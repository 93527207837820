import { GameObjects } from "phaser";
import { FactionTileInfo } from "../../../api/world";
import { queryClient } from "../../../query_client";
import { rootStore } from "../../../store";
import { Factions, SupportType } from "../../../types";
import { getKeys, range, toShortNumber } from "../../../utils";
import { FACTIONS_COLOR, HQs, TILE_SIZE } from "../config";
import { GameScene } from "../scene/world";
import { autorun } from "mobx";
import { Walls } from "./walls";

interface InvasionConstructor {
    scene: GameScene
}

type WorldUpdate = {
    x: number
    y: number
    t: FactionTileInfo
    supplyChainDiff?: { // for supply chain
        add: []
        remove: []
    },
}

type WorldUpdateSync = { changed: WorldUpdate[], hash: string }
type WorldUpdates = WorldUpdateSync | WorldUpdate


const FACTION_ALPHA = 0.2
enum Direction {
    UP = "UP",
    RIGHT = "RIGHT",
    DOWN = "DOWN",
    LEFT = "LEFT"
}

const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

/** handle mouse over highlight and tile selection */
export class Invasion {

    private socketStore = rootStore.socketStore
    private settingsStore = rootStore.settingsStore

    scene: GameScene
    factionLayer: Phaser.GameObjects.Layer
    linesLayer: Phaser.GameObjects.Layer
    supportLayer: Phaser.GameObjects.Layer
    supplyChainLayer: Phaser.GameObjects.Layer
    soldiersTextLayer: Phaser.GameObjects.Layer
    walls: Walls

    /* x - y - rect */
    invasionsObjects: {
        [key in number]: {
            [key in number]: {
                faction: Factions | null
                rect: GameObjects.Graphics | null
                text?: GameObjects.BitmapText // soldiers count
                lines?: GameObjects.Graphics[]
                supports: { [key in Factions]?: GameObjects.Sprite }
                supplyChain?: GameObjects.Sprite | null
            }
        }
    }
    world: FactionTileInfo[][]

    constructor({ scene }: InvasionConstructor) {
        // to show invasions
        // const invasionLayer = scene.map.createBlankLayer("invasion-layer", tileset!)

        this.scene = scene
        this.factionLayer = scene.add.layer().setDepth(5)
        this.linesLayer = scene.add.layer().setDepth(3)
        this.supportLayer = scene.add.layer().setDepth(15)
        this.supplyChainLayer = scene.add.layer()
        this.soldiersTextLayer = scene.add.layer().setDepth(1000)
        this.soldiersTextLayer.visible = false

        for (let faction of Object.values(Factions)) {
            if (faction === Factions.NEUTRAL)
                continue
            for (let direction of Object.values(Direction)) {
                let animFrames = [50, 51, 52, 53, 54]
                if (direction === Direction.UP) {
                    animFrames = [55, 56, 57, 58, 59]
                }
                else if (direction === Direction.RIGHT) {
                    animFrames = [60, 61, 62, 63, 64]
                }
                else if (direction === Direction.LEFT) {
                    animFrames = [65, 66, 67, 68, 69]
                }

                scene.anims.create({
                    key: `guardian_${faction}_${direction}_fight`,
                    frameRate: 7,
                    frames: scene.anims.generateFrameNumbers(`guardian_${faction}`, { frames: animFrames }),
                    repeat: -1
                });
                animFrames = [4, 5, 6, 7, 8, 9, 10, 11]

                if (direction === Direction.UP) {
                    animFrames = [40, 41, 42, 43, 44, 45, 46, 47]
                }
                else if (direction === Direction.RIGHT) {
                    animFrames = [16, 17, 18, 19, 20, 21, 22, 23]
                }
                else if (direction === Direction.LEFT) {
                    animFrames = [28, 29, 30, 31, 32, 33, 34, 35]
                }

                scene.anims.create({
                    key: `knight_${faction}_${direction}_fight`,
                    frameRate: 7,
                    frames: scene.anims.generateFrameNumbers(`knight_${faction}`, { frames: animFrames }),
                    repeat: -1
                })
            }
        }

        // neutral orc
        for (let direction of Object.values(Direction)) {
            let animFrames = [24, 25, 26, 27, 28, 29]
            if (direction === Direction.UP) {
                animFrames = [30, 31, 32, 33, 34, 35]
            }
            else if (direction === Direction.RIGHT) {
                animFrames = [36, 37, 38, 39, 40, 41]
            }
            else if (direction === Direction.LEFT) {
                animFrames = [42, 43, 44, 45, 46, 47]
            }

            scene.anims.create({
                key: `orc_${direction}_fight`,
                frameRate: 7,
                frames: scene.anims.generateFrameNumbers(`orc`, { frames: animFrames }),
                repeat: -1
            });
        }


        /** Display invasions info */
        scene.game.events.on('invasionInfo', (world: FactionTileInfo[][]) => {
            this.world = world
            try {
                this.supportLayer.removeAll(true)
                this.supplyChainLayer.removeAll(true)
                this.soldiersTextLayer.removeAll(true)
            }
            catch (e) {
                console.error(e)
            }
            if (this.invasionsObjects) {
                for (let x of getKeys(this.invasionsObjects)) {
                    for (let y of getKeys(this.invasionsObjects[x])) {
                        this.invasionsObjects[x][y].rect?.destroy()
                        this.invasionsObjects[x][y].lines?.forEach(l => l.destroy())
                        this.invasionsObjects[x][y].supplyChain?.destroy()
                        this.invasionsObjects[x][y].text?.destroy()
                    }
                }
            }
            this.invasionsObjects = {}
            for (let x = 0; x < scene.map.width; x++) {
                for (let y = 0; y < scene.map.height; y++) {
                    // debugger;
                    const tileInfo = world[x][y]
                    this.addTileInfo(x, y, tileInfo)
                }
            }
            this.walls.buildWallsInfo()
        }, scene.game)

        this.handleSocket()

        this.walls = new Walls({ scene: this.scene })

        autorun(() => {
            this.showSoldiers()
        })
    }

    addTileInfo(x: number, y: number, tileInfo: FactionTileInfo, old: FactionTileInfo | null = null) {
        // console.log(x, y, tileInfo)
        // const tile = this.map.putTileAt(11, x, y, true, invasionLayer);
        // rect.alpha = FACTION_ALPHA
        if (!this.invasionsObjects[x])
            this.invasionsObjects[x] = {}
        if (!this.invasionsObjects[x][y])
            this.invasionsObjects[x][y] = {
                faction: null,
                rect: null,
                supports: {},
            }

        this.invasionsObjects[x][y].rect?.destroy()
        this.invasionsObjects[x][y].lines?.forEach(l => l.destroy())
        this.invasionsObjects[x][y].supplyChain?.destroy()
        this.invasionsObjects[x][y].supplyChain = null
        this.invasionsObjects[x][y].text?.destroy()

        this.colorTile(x, y, tileInfo.f, { animated: !!old && old.f !== tileInfo.f, redrawNextTiles: !!old })

        if (old) {
            this.walls.updateWall(x, y, true)
        }

        if (old)
            this.showSoldiersIncome(x, y, tileInfo, old)

        this.invasionsObjects[x][y].faction = tileInfo.f

        if (!tileInfo.c && tileInfo.f !== Factions.NEUTRAL && tileInfo.f !== null) {
            this.addMissingSupplyChain(x, y)
        }

        // should we rerender all supports ?
        let rerender = false
        if (!old)
            rerender = true
        if (old && (old.k !== tileInfo.k || old.g !== tileInfo.g))
            rerender = true

        if (rerender) {
            this.removeAllSupports(x, y)
            if (tileInfo.f === Factions.NEUTRAL) {
                // add orc
                this.addOrc(x, y)
            }
            if (tileInfo.g && tileInfo.g.length >= 1 && tileInfo.f !== Factions.NEUTRAL) {
                // add guardian
                this.addGuardian(x, y, tileInfo.f)
            }
            if (tileInfo.k) {
                // add knight
                for (let i = 0; i < tileInfo.k.length; i++) {

                    let faction = null
                    if (tileInfo.k[i].length > 0) {
                        switch (i) {
                            case 0:
                                faction = Factions.BLUE
                                break
                            case 1:
                                faction = Factions.GREEN
                                break;
                            case 2:
                                faction = Factions.RED
                                break;
                            case 3:
                                faction = Factions.YELLOW
                                break;
                        }
                        if (faction)
                            this.addKnight(x, y, faction!)
                    }
                }
            }
        }
    }

    getInvasionInfo(x: number, y: number) {
        if (x < 0 || x >= this.scene.map.width || y < 0 || y >= this.scene.map.height || !this.world[x] || !this.world[x][y])
            return null
        return this.world[x][y]
    }

    getRandomEnemyDirection(x: number, y: number) {
        const directions = this.getEnemyDirections(x, y)
        if (!directions)
            return null
        return directions[Math.floor(Math.random() * directions.length)]
    }

    getRandomAlliedDirection(x: number, y: number, faction: Factions) {
        const directions = this.getAlliedDirections(x, y, faction)
        if (!directions)
            return null
        return directions[Math.floor(Math.random() * directions.length)]
    }

    getEnemyDirections(x: number, y: number, includeNeutral = false) {
        const tile = this.getInvasionInfo(x, y)
        if (!tile)
            return []
        // get tile from all directions
        const topTile = this.getInvasionInfo(x, y - 1)
        const bottomTile = this.getInvasionInfo(x, y + 1)
        const leftTile = this.getInvasionInfo(x - 1, y)
        const rightTile = this.getInvasionInfo(x + 1, y)
        // get the direction of the enemy
        const directions = []

        if ((includeNeutral && (!topTile || topTile.f === null)) || (topTile && topTile.f && topTile.f != tile.f))
            directions.push(Direction.UP)
        if ((includeNeutral && (!bottomTile || bottomTile.f === null)) || (bottomTile && bottomTile.f && bottomTile.f != tile.f))
            directions.push(Direction.DOWN)
        if ((includeNeutral && (!leftTile || leftTile.f === null)) || (leftTile && leftTile.f && leftTile.f != tile.f))
            directions.push(Direction.LEFT)
        if ((includeNeutral && (!rightTile || rightTile.f === null)) || (rightTile && rightTile.f && rightTile.f != tile.f))
            directions.push(Direction.RIGHT)
        return directions
    }
    getAlliedDirections(x: number, y: number, faction: Factions) {
        const tile = this.getInvasionInfo(x, y)
        if (!tile)
            return []
        // get tile from all directions
        const topTile = this.getInvasionInfo(x, y - 1)
        const bottomTile = this.getInvasionInfo(x, y + 1)
        const leftTile = this.getInvasionInfo(x - 1, y)
        const rightTile = this.getInvasionInfo(x + 1, y)
        // get the direction of the enemy
        const directions = []

        if ((topTile && topTile.f && topTile.f == faction))
            directions.push(Direction.UP)
        if ((bottomTile && bottomTile.f && bottomTile.f == faction))
            directions.push(Direction.DOWN)
        if ((leftTile && leftTile.f && leftTile.f == faction))
            directions.push(Direction.LEFT)
        if ((rightTile && rightTile.f && rightTile.f == faction))
            directions.push(Direction.RIGHT)
        return directions
    }

    colorTile(x: number, y: number, faction: Factions, config: { animated: boolean, redrawNextTiles?: boolean } = { animated: false, redrawNextTiles: false }) {
        /** color a tile according to faction color */

        // console.log("color tile", x, y, faction, config.animated, config.redrawNextTiles)
        if (x < 0 || x >= this.scene.map.width || y < 0 || y >= this.scene.map.height)
            return null
        const tile = this.getInvasionInfo(x, y)
        if (!tile)
            return null

        if (this.invasionsObjects[x] && this.invasionsObjects[x][y] && this.invasionsObjects[x][y].rect) {
            this.invasionsObjects[x][y].rect?.destroy()
            this.invasionsObjects[x][y].text?.destroy()
            this.invasionsObjects[x][y].lines?.forEach(l => l.destroy())
        }


        if (!faction) {
            if (config.redrawNextTiles) {
                if (x > 0)
                    this.colorTile(x - 1, y, this.world[x - 1][y].f, { animated: false, redrawNextTiles: false })
                if (x < this.scene.map.width - 1)
                    this.colorTile(x + 1, y, this.world[x + 1][y].f, { animated: false, redrawNextTiles: false })
                if (y > 0)
                    this.colorTile(x, y - 1, this.world[x][y - 1].f, { animated: false, redrawNextTiles: false })
                if (y < this.scene.map.height - 1)
                    this.colorTile(x, y + 1, this.world[x][y + 1].f, { animated: false, redrawNextTiles: false })
            }
            return null
        }
        this.addSoldiersText(x, y, tile.s)
        if (faction === Factions.NEUTRAL)
            return null

        const color = FACTIONS_COLOR[faction]
        // const rect = scene.add.rectangle(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2, TILE_SIZE, TILE_SIZE, color)
        const rect = this.scene.add.graphics()
        rect.fillStyle(color, 1.0)

        const directions = this.getEnemyDirections(x, y, true)
        const lines = []
        // const borders = { tl: 0, tr: 0, bl: 0, br: 0 }
        // const borderRadius = 16
        if (directions.includes(Direction.UP)) {
            const line = this.scene.add.graphics()
            line.fillStyle(color, 1.0)
            line.fillRect(x * TILE_SIZE, y * TILE_SIZE, TILE_SIZE, 2)
            lines.push(line)
        }
        if (directions.includes(Direction.DOWN)) {
            const line = this.scene.add.graphics()
            line.fillStyle(color, 1.0)
            line.fillRect(x * TILE_SIZE, y * TILE_SIZE + TILE_SIZE - 2, TILE_SIZE, 2)
            lines.push(line)
        }
        if (directions.includes(Direction.LEFT)) {
            const line = this.scene.add.graphics()
            line.fillStyle(color, 1.0)
            line.fillRect(x * TILE_SIZE, y * TILE_SIZE, 2, TILE_SIZE)
            lines.push(line)
        }
        if (directions.includes(Direction.RIGHT)) {
            const line = this.scene.add.graphics()
            line.fillStyle(color, 1.0)
            line.fillRect(x * TILE_SIZE + TILE_SIZE - 2, y * TILE_SIZE, 2, TILE_SIZE)
            lines.push(line)
        }
        // rect.fillRoundedRect(x * TILE_SIZE, y * TILE_SIZE, TILE_SIZE, TILE_SIZE, borders)
        rect.fillRect(x * TILE_SIZE, y * TILE_SIZE, TILE_SIZE, TILE_SIZE)

        // slow transition to new color        
        if (config.animated) {
            rect.alpha = 1.0
            if (tile.f !== Factions.NEUTRAL) {
                this.scene.tweens.add({
                    targets: rect,
                    props: {
                        alpha: { value: FACTION_ALPHA, duration: 500 },
                    },
                    repeat: 0
                })

            }
            else {
                this.scene.tweens.add({
                    targets: rect,
                    props: {
                        alpha: { value: 0.0, duration: 500 },
                    },
                    repeat: 0,
                })
            }
        }
        else {
            rect.alpha = FACTION_ALPHA
        }

        this.invasionsObjects[x][y].rect = rect
        this.invasionsObjects[x][y].lines = lines
        this.factionLayer.add(rect)
        for (let line of lines)
            this.linesLayer.add(line)

        if (config.redrawNextTiles) {
            if (x > 0)
                this.colorTile(x - 1, y, this.world[x - 1][y].f, { animated: false, redrawNextTiles: false })
            if (x < this.scene.map.width - 1)
                this.colorTile(x + 1, y, this.world[x + 1][y].f, { animated: false, redrawNextTiles: false })
            if (y > 0)
                this.colorTile(x, y - 1, this.world[x][y - 1].f, { animated: false, redrawNextTiles: false })
            if (y < this.scene.map.height - 1)
                this.colorTile(x, y + 1, this.world[x][y + 1].f, { animated: false, redrawNextTiles: false })
        }

        return rect
    }

    showSoldiersIncome(x: number, y: number, _new: FactionTileInfo, old: FactionTileInfo) {
        /** show soldiers income with a floating text */
        let soldiers = null
        let faction = null
        if (_new.f === null) {
            soldiers = -old.s
            faction = old.f
        }
        else if (_new.f !== old.f) {
            faction = _new.f
            soldiers = _new.s
        }
        else {
            soldiers = _new.s - old.s
            faction = _new.f
        }
        // can happen on pillage beceause HQ don't have soldiers
        if (soldiers === 0 || isNaN(soldiers))
            return

        let soldiersText = soldiers > 0 ? `+${soldiers}` : `${soldiers}`
        const text = this.scene.add.bitmapText(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2, "retrogaming", soldiersText, 16)
        text.setOrigin(0.5)
        text.setTintFill(FACTIONS_COLOR[faction])

        this.scene.tweens.add({
            targets: text,
            props: {
                y: { value: text.y - 50, duration: 500 },
                alpha: { value: 0, duration: 500 },
            },
            repeat: 0,
            callbacks: {
                onComplete: () => {
                    text.destroy()
                }
            }
        })
    }

    addSoldiersText(x: number, y: number, count: number) {
        /** set soldiers text */

        // don't show text on HQ
        for(let hq of getKeys(HQs)){
            if(HQs[hq].x === x && HQs[hq].y === y){
                return
            }
        }

        // first, cleanup
        this.invasionsObjects[x][y].text?.destroy()

        // the longer the number, the smaller the font size
        const shortNumber = toShortNumber(count)
        const fontSize = shortNumber.length > 2 ? 12 : 16
        const text = this.scene.add.bitmapText(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2, "retrogaming", shortNumber, fontSize)
        // const faction = this.world[x][y].f
        // let color = 0xffffff
        // if (faction === Factions.RED)
        //     color = 0x0000ff
        // else if (faction === Factions.GREEN)
        //     color = 0xff0000
        // else if (faction === Factions.BLUE)
        //     color = 0xffff00
        // else if (faction === Factions.YELLOW)
        //     color = 0x0000ff


        text.setTintFill(0xffffff)
        text.setOrigin(0.5)
        text.setDropShadow(1, 1, 0x000000, 1)
        text.setAlpha(range(0, 1000, 0.6, 1, count))

        // const text2 = this.scene.add.bitmapText(x * TILE_SIZE + TILE_SIZE / 2 + 1, y * TILE_SIZE + TILE_SIZE / 2 + 1, "retrogaming", shortNumber, fontSize)
        // text2.setTintFill(0xffffff)
        // text2.setOrigin(0.5)

        // const textGroup = this.scene.add.container()
        // textGroup.add(text)
        // // textGroup.add(text2)
        // textGroup.setAlpha(range(0, 1000, 0.6, 1, count))
        this.soldiersTextLayer.add(text)
        this.invasionsObjects[x][y].text = text
    }

    addGuardian(x: number, y: number, faction: Factions) {
        const offset = this.getXYOffset(x, y, SupportType.GUARDIAN, faction)
        if (faction === Factions.NEUTRAL)
            return
        const guardian = this.scene.add.sprite(x * TILE_SIZE + TILE_SIZE / 2 + offset.x, y * TILE_SIZE + TILE_SIZE / 2 + offset.y, `guardian_${faction}`)
        if (offset.direction)
            guardian.play({ key: `guardian_${faction}_${offset.direction}_fight`, delay: random(0, 500), repeatDelay: random(0, 500) })
        this.supportLayer.add(guardian)
        this.invasionsObjects[x][y].supports[faction] = guardian
    }

    addKnight(x: number, y: number, faction: Factions) {
        const offset = this.getXYOffset(x, y, SupportType.KNIGHT, faction)
        if (faction === Factions.NEUTRAL)
            return
        const knight = this.scene.add.sprite(x * TILE_SIZE + TILE_SIZE / 2 + offset.x, y * TILE_SIZE + TILE_SIZE / 2 + offset.y, `knight_${faction}`)
        if (offset.direction)
            knight.play({ key: `knight_${faction}_${offset.direction}_fight`, delay: random(0, 500), repeatDelay: random(0, 500) })
        this.supportLayer.add(knight)
        this.invasionsObjects[x][y].supports[faction] = knight
    }

    addOrc(x: number, y: number) {
        const offset = this.getXYOffset(x, y, SupportType.GUARDIAN, Factions.NEUTRAL)
        const orc = this.scene.add.sprite(x * TILE_SIZE + TILE_SIZE / 2 + offset.x, y * TILE_SIZE + TILE_SIZE / 2 + offset.y, `orc`)
        if (offset.direction)
            orc.play({ key: `orc_${offset.direction}_fight`, delay: random(0, 500), repeatDelay: random(0, 500) })
        this.supportLayer.add(orc)
        this.invasionsObjects[x][y].supports[Factions.NEUTRAL] = orc
    }

    /** remove all supports from a position */
    removeAllSupports(x: number, y: number) {
        for (let faction of Object.values(Factions)) {
            this.invasionsObjects[x][y].supports[faction]?.destroy()
            // if (faction === Factions.NEUTRAL)
            //     continue
            // if (this.world[x][y].k)
            //     this.world[x][y].k![INVASION_FACTION[faction]] = 0
            // this.world[x][y].g = 0
        }
    }


    addMissingSupplyChain(x: number, y: number) {
        if (!this.invasionsObjects || this.invasionsObjects[x][y].supplyChain) {
            return
        }
        const cross = this.scene.add.sprite(x * TILE_SIZE + TILE_SIZE / 2 + 8, y * TILE_SIZE + TILE_SIZE / 2 - 8, `misc`, 0).setScale(0.5)
        cross.alpha = 0.7
        this.supplyChainLayer.add(cross)
        this.invasionsObjects[x][y].supplyChain = cross
    }

    removeMissingSupplyChain(x: number, y: number) {
        if (this.invasionsObjects[x][y] && this.invasionsObjects[x][y].supplyChain)
            this.invasionsObjects[x][y].supplyChain?.destroy()
    }


    getXYOffset(x: number, y: number, supportType: SupportType, faction: Factions) {
        /** get x y offset position for supports (random value + direction of enemy) */
        let xOffset = 0
        let yOffset = 0

        let direction = supportType === SupportType.GUARDIAN ? this.getRandomEnemyDirection(x, y) : this.getRandomAlliedDirection(x, y, faction)

        let forwardOffset = [6, 14]
        const lateralOffset = [-6, 6]
        if (supportType === SupportType.KNIGHT) {
            forwardOffset = [-10, -16]
            // revert direction
            if (direction === Direction.UP)
                direction = Direction.DOWN
            else if (direction === Direction.DOWN)
                direction = Direction.UP
            else if (direction === Direction.LEFT)
                direction = Direction.RIGHT
            else if (direction === Direction.RIGHT)
                direction = Direction.LEFT
        }
        if (direction === Direction.UP) {
            yOffset = -random(forwardOffset[0], forwardOffset[1])
            xOffset = random(lateralOffset[0], lateralOffset[1])
        }
        else if (direction === Direction.RIGHT) {
            xOffset = random(forwardOffset[0], forwardOffset[1])
            yOffset = random(lateralOffset[0], lateralOffset[1])
        }
        else if (direction === Direction.DOWN) {
            yOffset = random(forwardOffset[0], forwardOffset[1])
            xOffset = random(lateralOffset[0], lateralOffset[1])
        }
        else if (direction === Direction.LEFT) {
            xOffset = -random(forwardOffset[0], forwardOffset[1])
            yOffset = random(lateralOffset[0], lateralOffset[1])
        }

        return { direction: direction, x: xOffset, y: yOffset }
    }

    handleSocket() {
        // if (!window.worldSocket?.OPEN) {
        // connect to socket


        // new WebSocket(WSConnectionString)
        // console.log(window.worldSocket)
        this.socketStore.startWorldSocket()


        this.socketStore.worldSocket!.addEventListener('open', () => {
            if (this.socketStore.worldSocket!.retryCount === 0) {
                // first connected
            } else {
                // reconnected
                queryClient.invalidateQueries({ queryKey: ["worldInfo"] })
            }
        });
        this.socketStore.worldSocket!.addEventListener('message', (e) => {

            let worldUpdates: WorldUpdates = JSON.parse(e.data)

            if (!this.invasionsObjects)
                return

            let updates = []
            // @ts-ignore : don't know why it's not working
            if (worldUpdates.changed) {
                // @ts-ignore
                updates = worldUpdates.changed
            }
            // we received a sync message
            else
                updates = [worldUpdates]


            for (const update of updates) {

                // copy old tile info
                let existing = null
                try {
                    existing = { ...this.world[update.x][update.y] }
                }
                catch (e) {
                    console.warn(update)
                    console.log(this.world)
                    return
                }
                this.world[update.x][update.y] = update.t

                this.scene.mapStore.setTileInfo(update.x, update.y, update.t)
                if (update.supplyChainDiff) {
                    this.scene.mapStore.addSupplyChainDiff(update.supplyChainDiff)
                    if (update.supplyChainDiff.add) {
                        for (let pos of update.supplyChainDiff.add) {
                            this.world[pos[0]][pos[1]].c = true
                            this.world[pos[0]][pos[1]].cd = null
                            this.addTileInfo(pos[0], pos[1], this.world[pos[0]][pos[1]])
                        }
                    }
                    if (update.supplyChainDiff.remove) {
                        for (let pos of update.supplyChainDiff.remove) {
                            this.world[pos[0]][pos[1]].c = false
                            this.world[pos[0]][pos[1]].cd = new Date().valueOf() / 1000
                            this.addTileInfo(pos[0], pos[1], this.world[pos[0]][pos[1]])
                        }
                    }
                }


                this.addTileInfo(update.x, update.y, update.t, existing)

            }




            // console.log(this.scene.mapStore.hashMap())
            // check if we're in sync with the server
            // @ts-ignore
            if (worldUpdates.hash && this.scene.mapStore.hashMap() !== worldUpdates.hash) {
                this.scene.mapStore.incrementFailedSyncCheck()

                // const newTilesInfo = worldUpdates.all
                // console.log("compare")
                // // compare newTilesInfo with this.world
                // for (let x = 0; x < this.scene.map.width; x++) {
                //     for (let y = 0; y < this.scene.map.height; y++) {
                //         for (let key of Object.keys(newTilesInfo[x][y])) {
                //             if (key != 'g' && newTilesInfo[x][y][key] !== this.world[x][y][key]) {
                //                 console.log('diff', x, y, key, newTilesInfo[x][y], this.world[x][y])
                //             }
                //         }
                //     }
                // }
            }

        })



    }
    showSoldiers() {
        this.soldiersTextLayer.visible = this.settingsStore.showMapSoldiers
    }
}

