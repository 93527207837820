import { Divider, HStack, Stack, Text, Tooltip } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { loot } from "../../../api/world"
import { useGameId, useResources } from "../../../hooks/query"
import { UISpendResources } from "../../../hooks/utils"
import { TileInfo } from "../../../store/MapStore"
import { ResourceType } from "../../../types"
import Button from "../../common/Button"
import GameIcon from "../../common/GameIcon"
import HelpText from "../../common/HelpText"

interface PillageProps {
    tileInfo: TileInfo
}
const Pillage: FunctionComponent<PillageProps> = ({ tileInfo }) => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const resources = useResources()
    const max = Math.min(tileInfo.victoryPoints * 5, 1000)

    // send soldiers for pillage
    const lootMutation = useMutation({
        mutationFn: (soldiers: number) => loot(gameId, tileInfo.x, tileInfo.y, soldiers),
        onMutate: (soldiers) => {
            UISpendResources({ queryClient, resources: { soldiers } })
        },
    })

    return (
        <>
            <Divider />
            <Stack>
                <HelpText tooltip="Send soldiers to loot victory points. This victory points are not stolen from the other faction. Every hour, new victory points are generated depending on the faction total victory points. Maximum is 5000 points.">
                    <Text as="span">Plunder</Text>
                </HelpText>
                <Text fontSize="xs">5 soldiers are required to loot 1 victory point.</Text>
                <HStack justifyContent={"center"} gap="0">
                    <Text>
                        <GameIcon name={ResourceType.VICTORY_POINTS} />
                        {tileInfo.victoryPoints}
                    </Text>
                </HStack>

                {tileInfo.isValidForPillage.valid && (
                    <>
                        <HStack gap="0" justifyContent={"center"}>
                            <Button
                                onClick={() => {
                                    lootMutation.mutate(10)
                                }}
                                isDisabled={resources.soldiers < 10 || tileInfo.victoryPoints < 1}
                                margin="0.5"
                                p="2"
                                leftIcon={<GameIcon name={ResourceType.SOLDIER} />}
                            >
                                +10
                            </Button>
                            <Button
                                onClick={() => {
                                    lootMutation.mutate(100)
                                }}
                                isDisabled={resources.soldiers < 100 || tileInfo.victoryPoints < 10}
                                margin="0.5"
                                p="2"
                                leftIcon={<GameIcon name={ResourceType.SOLDIER} />}
                            >
                                +100
                            </Button>
                            <Button
                                onClick={() => {
                                    lootMutation.mutate(max)
                                }}
                                isDisabled={resources.soldiers < max}
                                margin="0.5"
                                p="2"
                                leftIcon={<GameIcon name={ResourceType.SOLDIER} />}
                            >
                                +{max}
                            </Button>
                        </HStack>
                    </>
                )}
            </Stack>
        </>
    )
}

export default Pillage
