
import { Box, BoxProps, Container, Image, Text, keyframes } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Factions, ResourceType, SupportType } from "../../types"
import { useHQInfo } from "../../hooks/query"

type Icon = {
    url: string
    label: string
    faction?:boolean
    useFactionUrl?: boolean
    animated?: false
} | {    
    url: string
    label: string
    useFactionUrl?: boolean
    animated: true
    size: number
    x: number
    y: number
    steps: number
    height?: number
    scale?: number /* default scale */
    marginTop?: number
}

export enum GameIconType {
    FORTIFICATION = "FORTIFICATION",
    IMPROVEMENT = "IMPROVEMENT",
}

type GameIcon = ResourceType | SupportType | GameIconType

export const ICONS: { [key in GameIcon]: Icon } = {
    [ResourceType.WOOD]: { url: '/icons/wood.png', label: "Wood" },
    [ResourceType.IRON]: { url: '/icons/iron.png', label: "Iron" },
    [ResourceType.SOLDIER]: { url: '/icons/soldier.png', label: "Soldier" },
    [ResourceType.WORKER]: { url: '/icons/worker.png', label: "Worker" },
    [SupportType.GUARDIAN]: { url: '/icons/guardian.png', label: "Guardian", useFactionUrl: true, animated: true, size: 16, x: 1, y: 0, steps: 4 },
    [SupportType.KNIGHT]: { url: '/icons/knight.png', label: "Knight", useFactionUrl: true, animated: true, size: 32, x: 1, y: 0, steps: 4, height: 28, scale: 0.8, marginTop: -3 },
    [ResourceType.VICTORY_POINTS]: { url: '/icons/victory.png', label: "Victory points" },
    [GameIconType.FORTIFICATION]: { url: '/icons/fortification.png', label: "Fortification" },
    [GameIconType.IMPROVEMENT]: { url: '/icons/improvement.png', label: "Improvement" },
}


interface GameIconProps extends BoxProps {
    name: keyof typeof ICONS
    faction?: Factions
    scale?: number
    inactive?: boolean
}
const GameIcon: FunctionComponent<GameIconProps> = ({ name, faction, scale, inactive=false, ...boxProps }) => {
    const icon = ICONS[name]
    if(!icon){
        console.error(`Icon ${name} not found`)
        return <></>
    }
    let url = icon.url
    if (icon.useFactionUrl === true){
        const { data } = useHQInfo()
        const iconFaction = faction ? faction : data?.hq.faction
        url = url.replace('.png', `_${iconFaction}.png`)
    }
    const finalScale:number = (scale ? scale : icon.animated && icon.scale) || 1
    if (icon.animated) {
        const animation = keyframes`
            from{background-position-x:-${icon.x! * icon.size!}px;}
            to{background-position-x:-${(icon.x! + icon.steps!) * icon.size!}px;}
        `

        // remove 8 px width for knights and apply weird margin top inverse to scale
        return <Box transform={ `scale(${finalScale})`} 
                    width={`${icon.size === 32 ? icon.size - 8 : icon.size}px`}
                    ms={icon.size === 32 ? "-8px" : 0}
                    mt={icon.marginTop ? `${icon.marginTop / finalScale}px` : 0}
                    height={`${icon.height || icon.size}px`} 
                    display="inline-block" 
                    as="div" 
                    title={icon.label}
                    bgImage={url} 
                    bgPos={"0px 0px"} 
                    filter={inactive ? "grayscale(1)" : "none"}
                    animation={`${animation} 0.8s steps(${icon.steps}) infinite`} 
                    {...boxProps} />
    }

    return (
        <Box height="1em" width="1em" display="inline-block" as="span" scale={scale} {...boxProps}>
            <Image src={url} title={icon.label} alt={icon.label} scale={scale} />
        </Box>

    )
}

export default GameIcon
