import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useRef, useEffect, useState } from "react"
import { useStore } from "../../store"
import Channel from "./Channel"
import { useIsVisible } from "../../hooks/utils"

interface ChatProps {
}
const Chat: FunctionComponent<ChatProps> = observer(() => {
    const { chatStore } = useStore()
    const ref = useRef<HTMLInputElement>(null)

    const handleTabsChange = (index: number) => {
        chatStore.setActiveChannel(index)
    }

    const isVisible = useIsVisible(ref)

    useEffect(() => {
        chatStore.setIsChatVisible(isVisible)
    }, [isVisible])

    return (
        <Box className="chat" ref={ref}>
            <Tabs index={chatStore.activeChannel} onChange={handleTabsChange} variant="chat" padding="1">
                <TabList overflowX="auto" overflowY="hidden">
                    {chatStore.channels.map((channel) => (
                        <Tab
                            padding="1"
                            paddingX="2"
                            key={channel.name}
                        >
                            {channel.name}{" "}
                            {channel.hasUnreadMessages && (
                                <Box
                                    display={"inline-block"}
                                    borderRadius="50%"
                                    width="8px"
                                    height="8px"
                                    bgColor="red.800"
                                    position="relative"
                                    top="-6px"
                                    left="0"
                                ></Box>
                            )}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {chatStore.channels.map((channel) => (
                        <TabPanel key={channel.id}>
                            <Channel channelId={channel.id}></Channel>
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
    )
})

export default Chat
