import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    HStack,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Text,
    keyframes,
    useDisclosure,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent, useEffect, useState } from "react"
import { BsFillQuestionDiamondFill } from "react-icons/bs"
import { getQuest, getReward } from "../../api/game"
import { AnimatedBox } from "../common/Motion"
import Resources from "../resources/Resources"
import { QUESTS } from "./config"
import { useIsMobile } from "../../hooks/utils"
import { useGameId } from "../../hooks/query"
// import Button from "../common/Button"

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

interface QuestBubbleProps {}

export const QuestBubble: FunctionComponent<QuestBubbleProps> = ({}) => {
    const gameId = useGameId()
    // const dragControls = useDragControls()
    const queryClient = useQueryClient()
    const { data: quest, isLoading } = useQuery({
        queryKey: ["quest"],
        queryFn: () => getQuest(gameId),
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: 0,
        refetchInterval: 0,
    })
    const reward = useMutation({
        mutationFn: () => getReward(gameId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["quest"] })
            queryClient.invalidateQueries({ queryKey: ["gatherResources"] })
        },
    })
    const { isOpen, onToggle, onClose, onOpen } = useDisclosure()
    const [index, setIndex] = useState(0)

    const questStep = quest ? QUESTS[quest.name][index] : null
    const hasNextStep = quest ? index < QUESTS[quest.name].length - 1 : false

    const goNextStep = () => {
        if (hasNextStep) {
            setIndex(index + 1)
        }
    }

    useEffect(() => {
        if (quest) {
            setIndex(0)
            onOpen()
        }
    }, [quest?.name])
    useEffect(() => {
        if (quest?.completed) {
            onOpen()
        }
    }, [quest?.completed])

    const isMobile = useIsMobile()

    const { height, width } = getWindowDimensions()

    return (
        <>
            {quest && (
                <Box zIndex={100}>
                    <AnimatedBox
                        drag
                        dragConstraints={{ left: -width + 50, right: 0, top: 0, bottom: height - 150 }}
                        top="100px"
                        right="10px"
                        position="fixed"
                        borderColor="orange.600"
                        dragMomentum={false}
                        // onDragStart
                        // onDragEnd=
                        // dragControls={dragControls}
                    >
                        <Popover isOpen={isOpen} onClose={onClose} closeOnBlur={false}>
                            <PopoverTrigger>
                                <Button variant="none" onClick={onToggle}>
                                    <Icon
                                        aria-label="Quests"
                                        as={BsFillQuestionDiamondFill}
                                        boxSize="10"
                                        color="red.700"
                                    />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Tutorial</PopoverHeader>
                                <PopoverBody>
                                    <Text fontSize="sm">
                                        {isMobile && questStep?.textMobile ? questStep.textMobile : questStep?.text}
                                    </Text>
                                    {questStep?.task && (
                                        <Box
                                            border="1px solid"
                                            bgColor="orange.500"
                                            borderColor="orange.600"
                                            py="1"
                                            my="2"
                                            paddingLeft="2"
                                            textAlign={"center"}
                                        >
                                            <Checkbox mr="2" mt="1" isChecked={quest.completed} />
                                            <Text
                                                backgroundImage="linear-gradient(white, white)"
                                                backgroundRepeat="no-repeat"
                                                backgroundPosition="center left"
                                                backgroundSize={quest.completed ? "100% 3px" : "0% 3px"}
                                                transition="background-size 1s ease-in-out"
                                                as="span"
                                                // transition="background-size .4s ease"
                                                // _after={!quest.completed ? {} : {
                                                //     content: '" "',
                                                //     position: "absolute",
                                                //     opacity: 0.9,
                                                //     top: '50%',
                                                //     left: 0,
                                                //     width: "full",
                                                //     height: '3px',
                                                //     background: 'white',
                                                //     animation: scratchAnimation,
                                                //     animationFillMode: "forwards"
                                                // }}
                                                animation="opacity 1s linear"
                                                opacity={quest.completed ? 0.5 : 1}
                                            >
                                                {questStep.task}
                                            </Text>
                                        </Box>
                                    )}
                                    {!hasNextStep && quest.completed && questStep?.postComplete && (
                                        <Text fontSize="sm" layerStyle={"info"}>
                                            {questStep.postComplete}
                                        </Text>
                                    )}
                                    {!hasNextStep && (
                                        <HStack fontSize="sm" alignItems="center" justifyContent="space-between" p="2">
                                            <Text>Reward :</Text>
                                            <Resources {...quest.reward} cost={false} />
                                        </HStack>
                                    )}
                                </PopoverBody>
                                {(hasNextStep || quest.completed) && (
                                    <PopoverFooter
                                        border="0"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="end"
                                        pb={4}
                                    >
                                        <ButtonGroup size="sm">
                                            {hasNextStep && (
                                                <Button colorScheme="green" onClick={goNextStep}>
                                                    Next
                                                </Button>
                                            )}
                                            {quest.completed && questStep?.task && (
                                                <Button
                                                    colorScheme="blue"
                                                    isLoading={reward.isPending || isLoading}
                                                    onClick={() => reward.mutate()}
                                                >
                                                    Get reward
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </PopoverFooter>
                                )}
                            </PopoverContent>
                        </Popover>
                    </AnimatedBox>
                </Box>
            )}
        </>
    )
}
