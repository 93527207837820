import { Box, Flex, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Mod } from "../../api/game"
import GameIcon from "../common/GameIcon"
import { ResourceType } from "../../types"
import { useTranslation } from "react-i18next"
import Number from "../common/Number"

interface ModListProps {
    value: Mod[]
    level?: number
    global?: boolean
}

const ModList: FunctionComponent<ModListProps> = ({ value, level, global = false }) => {
    return (
        <Box>
            {value.map((mod, index) => (
                <ModComponent key={index} level={level} global={global} {...mod} />
            ))}
        </Box>
    )
}

interface ModProps extends Mod {
    level?: number
    global?: boolean
}

const ModComponent: FunctionComponent<ModProps> = ({ type, subtype, base, bonus, level = 1, global = false }) => {
    let iconName: ResourceType | null = null
    if (["storage", "production", "victory"].includes(type)) iconName = subtype as ResourceType

    if (subtype === "knightPower") {
        iconName = ResourceType.KNIGHT
    } else if (subtype === "guardianPower") {
        iconName = ResourceType.GUARDIAN
    }

    const label = type === "world" ? subtype : type
    const { t } = useTranslation()

    const numOfFractionDigits = ["soldiers", "workers"].includes(subtype) ? 2 : 1

    return (
        <Stack justifyContent="center" gap="0">
            {type === "talent" && (
                <Text variant="positive" fontWeight={"bold"}>
                    Unlocks specializations
                </Text>
            )}
            <Box alignItems={"center"} display={"flex"}>
                {iconName && <GameIcon name={iconName} me="2" />}
                {t(`effects.${label}.title`)}
                {bonus && <Number value={bonus * level} percentage showPlus ps="2" />}
                {base && <Number value={base * level} showPlus ps="1" numOfFractionDigits={numOfFractionDigits} />}
                {subtype === "victoryPoints" && (
                    <Text fontSize="xs" as="span">
                        /min
                    </Text>
                )}
            </Box>
            {global && <Text fontSize="xs">Apply to everyone in your faction</Text>}
        </Stack>
    )
}

export default ModList
