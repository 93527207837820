import { CloseIcon } from "@chakra-ui/icons"
import { Box, Center, Divider, HStack, Icon, IconButton, Skeleton, Stack, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AnimatePresence } from "framer-motion"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useReducer } from "react"
import { useTranslation } from "react-i18next"
import { PiPathFill } from "react-icons/pi"
import { RxCross2 } from "react-icons/rx"
import { invade, support } from "../../api/world"

import {
    useConfig,
    useEffects,
    useGameId,
    useInvasionInfo,
    useIsUnlocked,
    usePlayer,
    useResources,
} from "../../hooks/query"
import { UISpendResources, useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import { Factions, ResourceType, SupportType, TerrainType, UnlockType } from "../../types"
import Button from "../common/Button"
import DateComponent from "../common/DateComponent"
import GameIcon from "../common/GameIcon"
import HelpText from "../common/HelpText"
import { AnimatedBox } from "../common/Motion"
import ModList from "../resources/ModList"
import Supports from "./components/Supports"
import Countdown from "react-countdown"
import Supply from "./components/Supply"
import TileFortification from "./components/TileFortification"
import TileImprovement, { TileImprovements } from "./components/TileImprovement"
import Pillage from "./components/Pillage"
import Number from "../common/Number"

type MobileSelectedTileProps = {
    children: React.ReactNode
}

const DrawerSelectedTile: FunctionComponent<MobileSelectedTileProps> = observer(({ children }) => {
    const { mapStore } = useStore()

    return (
        <AnimatePresence>
            {mapStore.selectedTile && (
                <AnimatedBox
                    initial={{ opacity: 0, bottom: -140 }}
                    animate={{ opacity: 1, bottom: 33 }}
                    exit={{ opacity: 0, bottom: -140 }}
                    position="fixed"
                    left="0"
                    right="0"
                >
                    <IconButton
                        aria-label="close"
                        size="sm"
                        icon={<CloseIcon />}
                        position="absolute"
                        right="-1"
                        top="-1"
                        onClick={() => mapStore.unselectTile()}
                        zIndex={1}
                    />
                    <Box pointerEvents={"auto"}>{children}</Box>
                </AnimatedBox>
            )}
        </AnimatePresence>
        // <Box position="fixed" bottom="40px" left="0" right="0" bgColor="white">
        //             <SlideFade in={isOpen}>

        //     {children}
        //     </SlideFade>
        // </Box>
    )
})

const SelectedTile: FunctionComponent = observer(() => {
    const { mapStore } = useStore()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const queryClient = useQueryClient()
    const { t } = useTranslation()
    const mobile = useIsMobile()
    const selectedTile = mapStore.selectedTile
    const showGuardian = useIsUnlocked(UnlockType.GUARDIAN_TRAINING_CENTER)
    const showKnight = useIsUnlocked(UnlockType.KNIGHT_TRAINING_CENTER)
    const tileImprovementUnlocked = useIsUnlocked(UnlockType.UI_TILE_IMPROVEMENT)
    const tileFortificationUnlocked = useIsUnlocked(UnlockType.UI_TILE_FORTIFICATION)
    const { data: effects } = useEffects()
    const player = usePlayer()

    // const { data: tileInfo, isLoading: isLoadingTileInfo } = useTileInfo(
    //     selectedTile?.x,
    //     selectedTile?.y,
    //     mapStore.runFetch
    // )
    let tileInfo = null

    if (selectedTile) tileInfo = mapStore.getTileInfo(selectedTile!.x, selectedTile!.y)

    const ownTile = tileInfo?.faction === player?.faction
    const showTileFortification = tileInfo?.fortification.visible && (!ownTile || tileFortificationUnlocked)
    const showTileImprovement =
        tileInfo?.improvements.some((item) => item.visible) && (!ownTile || tileImprovementUnlocked)

    // const { isLoading: isLoadingInvasion } = useInvasionInfo()

    const resources = useResources()
    const config = useConfig()
    const gameId = useGameId()

    // send soldiers
    const invasion = useMutation({
        mutationFn: (soldiers: number) => invade(gameId, selectedTile!.x, selectedTile!.y, soldiers),
        onMutate: (soldiers: number) => {
            UISpendResources({ queryClient, resources: { soldiers } })
        },
        retry: 2,
    })

    // send guardian or knight
    const supportMutation = useMutation({
        mutationFn: (supportType: SupportType) => support(gameId, selectedTile!.x, selectedTile!.y, supportType),
        onMutate: (supportType: SupportType) => {
            UISpendResources({ queryClient, resources: { [supportType]: 100 } })
        },
        retry: 2,
    })

    const terrainType = selectedTile ? config?.world[selectedTile!.x][selectedTile!.y] : null
    const terrainTypeBonus = terrainType ? config?.terrainTypes[terrainType] : null

    // let hq = null
    // if (config) {
    //     hq = Object.entries(config.hqs).filter(
    //         ([key, value]) => selectedTile && value.x === selectedTile.x && value.y === selectedTile.y
    //     )
    //     if (hq.length) hq = hq[0][0]
    //     else hq = null
    // }

    if (!selectedTile || !tileInfo) return <></>
    const faction = tileInfo.faction
    const soldiers = tileInfo.soldiers || 0
    const supplied = tileInfo.supplied

    // invasionInfo.world![selectedTile.x!][selectedTile?.y!].f

    // resources.knight < 100 || !tileInfo?.isValidForKnight.valid || faction === Factions.NEUTRAL

    const Wrapper = mobile ? DrawerSelectedTile : Box

    // compute number of soldiers you need to send to gain support (share of defense soldiers and attack soldiers)
    let toSupportSoldiersOnDefense = 0
    let toSupportSoldiersOnAttack = 0
    let toSupportSoldiers = 0
    if (faction === player?.faction && soldiers < 10) toSupportSoldiersOnDefense = 10 - soldiers
    else if (faction !== player?.faction) {
        toSupportSoldiersOnAttack = soldiers
        toSupportSoldiersOnDefense = 10
    } else if (!faction) toSupportSoldiersOnDefense = 10
    if (effects) {
        toSupportSoldiersOnDefense = toSupportSoldiersOnDefense / (1 + effects?.world.defense.total / 100)
        if (toSupportSoldiersOnAttack) {
            toSupportSoldiersOnAttack =
                toSupportSoldiersOnAttack / (1 + (effects?.world.attack.total + tileInfo.attack) / 100)
            if (tileInfo.defense < 100)
                toSupportSoldiersOnAttack = toSupportSoldiersOnAttack / (1 - tileInfo.defense / 100)
            else toSupportSoldiersOnAttack = Infinity
        }
        toSupportSoldiers = toSupportSoldiersOnAttack + toSupportSoldiersOnDefense
    }
    toSupportSoldiers = Math.ceil(toSupportSoldiers)
    let defenseColor = "normal"

    if (ownTile) {
        if (tileInfo.defense < 0) defenseColor = "red"
        else if (tileInfo.defense > 0) defenseColor = "green"
    } else {
        if (tileInfo.defense < 0) defenseColor = "green"
        else if (tileInfo.defense > 0) defenseColor = "red"
    }
    return (
        <Wrapper aria-live="assertive">
            <Box
                minHeight="200"
                maxHeight={[250, 250, "none"]}
                overflowY={["scroll", "scroll", "auto"]}
                w="full"
                bgColor={faction && faction !== Factions.NEUTRAL ? faction : "orange.800"}
            >
                <>
                    <Stack justifyContent={"space-around"} p={[1, 1, 3]} gap={[0, 0, 2]}>
                        <HStack justifyContent={"space-between"} height="6">
                            <HStack>
                                {faction && faction !== Factions.NEUTRAL && (
                                    <>
                                        {supplied ? (
                                            <HelpText
                                                tooltip="This territory is connected to the faction's HQ"
                                                showHelpButton={false}
                                            >
                                                <Icon as={PiPathFill} color="green.500" />
                                            </HelpText>
                                        ) : (
                                            <HelpText
                                                tooltip="This territory is not connected to the faction's HQ. This means that every hour, 20% of its soldiers will die."
                                                showHelpButton={false}
                                            >
                                                <Icon as={RxCross2} color="red.500" />
                                            </HelpText>
                                        )}
                                    </>
                                )}
                                <Text fontSize="xs">
                                    {terrainType && (
                                        <>
                                            {terrainType === TerrainType.CASTLE ? (
                                                <HelpText
                                                    tooltip={
                                                        "You need to capture all the castle tile and hold it 12 hours to win."
                                                    }
                                                >
                                                    {t(`terrain.${terrainType}`)}
                                                </HelpText>
                                            ) : (
                                                <>{t(`terrain.${terrainType}`)}</>
                                            )}
                                        </>
                                    )}
                                </Text>
                            </HStack>
                            <Text fontSize="xs" pr={[10, 10, 10, 4]}>
                                X : {selectedTile!.x} - Y : {selectedTile!.y}
                            </Text>
                        </HStack>

                        {terrainTypeBonus && <ModList value={terrainTypeBonus} />}

                        {tileInfo.lostSupplyDate && tileInfo.faction != Factions.NEUTRAL && (
                            <Supply lostSupplyDate={tileInfo.lostSupplyDate} />
                        )}

                        <Divider />

                        <Stack>
                            <Stack justifyContent={"center"} gap="0" height="12">
                                {faction ? (
                                    <>
                                        <HStack justifyContent="center" alignItems="center">
                                            <Text>Faction</Text>
                                            <Text>{faction}</Text>
                                        </HStack>

                                        <HStack justifyContent="center" alignItems="center">
                                            <Text>Soldiers</Text>

                                            <Text>
                                                <GameIcon name={ResourceType.SOLDIER} />
                                                {soldiers}
                                            </Text>
                                        </HStack>
                                    </>
                                ) : (
                                    <HStack justifyContent={"center"} alignItems={"center"}>
                                        <Text>Neutral</Text>
                                    </HStack>
                                )}
                            </Stack>
                        </Stack>

                        {tileInfo.victoryPoints && <Pillage tileInfo={tileInfo} />}

                        <Divider />

                        <Box>
                            {!tileInfo && <Skeleton height="30px" />}
                            {tileInfo?.isValidForInvasion.valid && (
                                <>
                                    <HStack justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            Send <GameIcon name={ResourceType.SOLDIER} /> soldiers{" "}
                                        </Box>
                                        <Box>
                                            <Text>
                                                <HelpText tooltip="Defense of this terrain. For example, if the bonus is 50%, then only half the soldier sent will be effective.">
                                                    <>DEF</>
                                                </HelpText>
                                                <Number
                                                    value={tileInfo.defense}
                                                    percentage
                                                    color={defenseColor}
                                                    ml="2"
                                                />
                                            </Text>
                                        </Box>
                                    </HStack>

                                    <HStack gap="0" justifyContent={"center"}>
                                        <Button
                                            onClick={() => {
                                                invasion.mutate(1)
                                            }}
                                            isDisabled={resources.soldiers < 1}
                                            isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                            margin="0.5"
                                            p="2"
                                        >
                                            +1
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                invasion.mutate(10)
                                            }}
                                            isDisabled={resources.soldiers < 10}
                                            isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                            margin="0.5"
                                            p="2"
                                        >
                                            +10
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                invasion.mutate(100)
                                            }}
                                            isDisabled={resources.soldiers < 100}
                                            isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                            margin="0.5"
                                            p="2"
                                        >
                                            +100
                                        </Button>
                                    </HStack>

                                    {(showGuardian || showKnight) && (
                                        <HStack gap="0" justifyContent={"center"} wrap={"wrap"}>
                                            <Button
                                                tooltip={
                                                    "Send enough soldiers to get support (10 soldiers of your faction)."
                                                }
                                                onClick={() => {
                                                    invasion.mutate(toSupportSoldiers)
                                                }}
                                                isDisabled={
                                                    resources.soldiers < toSupportSoldiers || toSupportSoldiers === 0
                                                }
                                                isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                                margin="0.5"
                                                p="2"
                                            >
                                                +{toSupportSoldiers}
                                            </Button>
                                            <Button
                                                tooltip={"Send all your soldiers."}
                                                onClick={() => {
                                                    invasion.mutate(Math.floor(resources.soldiers))
                                                }}
                                                isDisabled={resources.soldiers < 1}
                                                isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                                margin="0.5"
                                                p="2"
                                            >
                                                +{Math.floor(resources.soldiers)}
                                            </Button>
                                            {showGuardian && (
                                                <Button
                                                    onClick={() => {
                                                        supportMutation.mutate(SupportType.GUARDIAN)
                                                    }}
                                                    isLoading={supportMutation.isPending}
                                                    colorScheme="green"
                                                    isDisabled={
                                                        resources.guardian < 100 || !tileInfo?.isValidForGuardian.valid
                                                    }
                                                    margin="0.5"
                                                    p="2"
                                                >
                                                    Send <GameIcon name={ResourceType.GUARDIAN} />
                                                </Button>
                                            )}
                                            {showKnight && (
                                                <Button
                                                    onClick={() => {
                                                        supportMutation.mutate(SupportType.KNIGHT)
                                                    }}
                                                    isLoading={supportMutation.isPending}
                                                    colorScheme="green"
                                                    isDisabled={
                                                        resources.knight < 100 ||
                                                        !tileInfo?.isValidForKnight.valid ||
                                                        !faction
                                                    }
                                                    margin="0.5"
                                                    p="2"
                                                >
                                                    <Box display="flex" alignItems="center">
                                                        Send <GameIcon name={ResourceType.KNIGHT} />
                                                    </Box>
                                                </Button>
                                            )}
                                        </HStack>
                                    )}
                                </>
                            )}

                            {tileInfo && (
                                <>
                                    <Supports tileInfo={tileInfo} />
                                </>
                            )}

                            {tileInfo && !tileInfo?.isValidForInvasion.valid && (
                                <Box layerStyle={"info"} color="orange.800" boxShadow={"md"}>
                                    <Text color="orange.800">You can't invade here</Text>
                                    {tileInfo?.isValidForInvasion.reason === "water" && (
                                        <Text variant="discreet" color="orange.800">
                                            You can't invade a water territory.
                                        </Text>
                                    )}
                                    {tileInfo?.isValidForInvasion.reason === "no support" && (
                                        <Text variant="discreet" color="orange.800">
                                            To invade this territory, it needs to have an adjacent territory with at
                                            least 10 soldiers of your faction.
                                        </Text>
                                    )}
                                    {tileInfo?.isValidForInvasion.reason === "no supply route" && (
                                        <Text variant="discreet" color="orange.800">
                                            This territory is not supplied.
                                        </Text>
                                    )}
                                    {tileInfo?.isValidForInvasion.reason === "hq" && (
                                        <Text variant="discreet" color="orange.800">
                                            You can't invade a faction castle.
                                        </Text>
                                    )}
                                </Box>
                            )}
                        </Box>

                        {(invasion.error && !invasion.isPending) ||
                            (supportMutation.error && !supportMutation.isPending && (
                                <Box layerStyle={"error"}>
                                    <Text>An error occurred...</Text>
                                </Box>
                            ))}

                        {showTileFortification && (
                            <>
                                <Divider my="2" />
                                <TileFortification />
                                {!showTileImprovement && <Divider my="2" />}
                            </>
                        )}

                        {showTileImprovement && (
                            <>
                                <Divider my="2" />
                                <TileImprovements />
                                <Divider my="2" />
                            </>
                        )}

                        {tileInfo?.captureDate && tileInfo.capturePlayer && (
                            <Center fontSize="xs">
                                <Stack alignItems={"center"} gap="0">
                                    <Text>
                                        {faction ? "Captured" : "Neutralized"} by : {tileInfo?.capturePlayer}
                                    </Text>
                                    <Text>
                                        on <DateComponent date={tileInfo.captureDate} />
                                    </Text>
                                </Stack>
                            </Center>
                        )}
                    </Stack>
                </>
            </Box>
        </Wrapper>
    )
})

export default SelectedTile
