import { makeObservable, computed, observable, action, onBecomeObserved, onBecomeUnobserved } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { queryClient } from "../query_client"
import ReconnectingWebSocket from "reconnecting-websocket"
import { HQInfo } from "../api/game"
import { BuildingType, Factions, UnlockType } from "../types"
import { getKeys } from "../utils"
import { WS_BASE_URL } from "../config"

export type Notification = {
    id: string,
    type: "achievement" | "unlock" | "message" | "error" | "castle",
    title: string,
    message?: string
    faction?: Factions
}

export default class SocketStore extends BaseStore {

    notifications: Notification[] = []
    notificationsSocket: ReconnectingWebSocket | null = null
    worldSocket: ReconnectingWebSocket | null = null

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            notifications: observable,
            startNotificationsSocket: action,
            stopNotificationsSocket: action,
            startWorldSocket: action,
            stopWorldSocket: action,
            markAsRead: action,
            addNotification: action,
        })
        onBecomeObserved(this, "notifications", this.startNotificationsSocket.bind(this))
        onBecomeUnobserved(this, "notifications", this.stopNotificationsSocket.bind(this))
    }

    startNotificationsSocket() {
        // connect to websocket

        if (this.notificationsSocket?.OPEN || !this.rootStore.userStore.player?.userId)
            return


        this.notificationsSocket = new ReconnectingWebSocket(WS_BASE_URL + "notification/" + this.rootStore.userStore.player.userId, ['Token', `${this.rootStore.userStore.apiToken}`], {
            debug: false,
            // WebSocket: createWebSocketClass({
            //     headers: {
            //         Authorization: `Bearer ${this.rootStore.userStore.apiToken}`,
            //     },
            // })
        })
        this.notificationsSocket.addEventListener('message', (event) => {

            const data = JSON.parse(event.data)
            const payload = data['payload']
            // console.log(payload)
            if (payload['type'] == "quest") {
                queryClient.invalidateQueries({ queryKey: ["quest"] })
            }
            else if (payload['type'] == "achievement") {
                queryClient.invalidateQueries({ queryKey: ["achievements"] })
                this.addNotification(payload)
            }
            else if (payload['type'] == "castle") {
                queryClient.invalidateQueries({ queryKey: ["game"] })
                if (payload['status'] == 'start')
                    this.addNotification(payload)
            }
            else if (payload['type'] == "unlock") {
                this.addNotification(payload)
                queryClient.setQueryData(["hqInfo"], (oldData: HQInfo | undefined) => {
                    if (oldData) {
                        return {
                            ...oldData,
                            unlocks: [...oldData.unlocks, payload['title'] as UnlockType]
                        }
                    }
                    return undefined
                })
            }
        })
    }

    stopNotificationsSocket() {
        // disconnect from websocket
        this.notificationsSocket?.close()
        this.notificationsSocket = null
    }


    startWorldSocket() {
        // connect to world websocket
        var WSConnectionString = `${WS_BASE_URL}game/${this.rootStore.userStore.gameId}/world_update`
        if (!this.worldSocket?.OPEN)
            this.worldSocket = new ReconnectingWebSocket(WSConnectionString, ['Token', `${this.rootStore.userStore.apiToken}`], {
                debug: false
            })
    }

    stopWorldSocket() {
        // disconnect from world websocket
        this.worldSocket?.close()
        this.worldSocket = null
    }

    addNotification(notification: Notification) {
        // add notification to list
        this.notifications.push(notification)
    }

    markAsRead(notificationId: string) {
        // mark notification as read
        this.notifications = this.notifications.filter(n => n.id != notificationId)
    }



    debug_createNotifications() {
        for (let unlock of getKeys(UnlockType)) {
            this.addNotification({
                id: "unlock_" + unlock,
                type: "unlock",
                title: unlock,
            })
        }

        this.addNotification({
            id: "5",
            type: "achievement",
            title: "SOLDIERS_SENT_1",
        })

    }

}
