import { useQuery } from "@tanstack/react-query";
import { SpecializationType } from "../types";
import { authApi } from "./base";
import { Mod } from "./game";
import { useGameId } from "../hooks/query";

export async function pickSpecialization(gameId: number, specialization: SpecializationType) {
    console.log("pickSpecialization", gameId, specialization)
    const response = await authApi.post(`/game/${gameId}/specialization/pick`, {}, { params: { specialization } })
    return response.data
}


/** return number of players per spec */
export async function specsInfos(gameId: number): Promise<{ [key in SpecializationType]: number }>   {
    const response = await authApi.get(`/game/${gameId}/specialization/specs_infos`)
    return response.data
}

export interface Talent {
    name: string
    maxPoints: number
    tiers: number
    points: number
    global: boolean
    baseEffects: Mod[]
}

export async function getTalents(gameId: number): Promise<Talent[]> {
    // get player info
    const talents = await authApi.get(`/game/${gameId}/specialization/talents`)
    return talents.data
}

export const useTalents = () => {
    // get players talents
    const gameId = useGameId()
    return useQuery({ queryKey: ["talents"], queryFn: () => getTalents(gameId) })
}

export async function pickTalent(gameId: number, talent: string) {
    const response = await authApi.post(`/game/${gameId}/specialization/pick_talent`, {}, { params: { talent } })
    return response.data
}