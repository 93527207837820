import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Center,
    HStack,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    useTheme,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent, memo, useMemo, useReducer, useEffect } from "react"
import { EventProject as EventProjectInterface, event_work, work } from "../../api/projects"
import GameIcon from "../../components/common/GameIcon"
import Progress from "../../components/common/ProgressWithText"
import ModList from "../../components/resources/ModList"
import { useGameId, useResources } from "../../hooks/query"
import { UISpendResources } from "../../hooks/utils"
import { Factions, FactionsWithoutNeutral, ResourceType } from "../../types"
import Countdown from "react-countdown"
import { useStore } from "../../store"
import Number from "../common/Number"
import { AnimatedBox } from "../common/Motion"
import { useTranslation } from "react-i18next"
import Button from "../common/Button"
import Resources from "../resources/Resources"

interface EventProjectProps extends EventProjectInterface {}

const EventProject: FunctionComponent<EventProjectProps> = memo(
    ({ id, name, baseEffects, gain, hiddenProgress, endDate, workers }) => {
        const resources = useResources()
        const queryClient = useQueryClient()
        const { t } = useTranslation()
        const { userStore } = useStore()
        const [, forceUpdate] = useReducer((x) => x + 1, 0)
        const gameId = useGameId()

        // send workers
        const workMutation = useMutation({
            mutationFn: (workers: number) => event_work(gameId, id, workers),

            onMutate: (workers) => {
                UISpendResources({ queryClient, resources: { workers: workers } })
            },
            onSuccess: (data) => {
                // handled by socket
            },
        })

        // get current max value (winner) to compute progress percentage
        let maxValue = 100
        let factions = []
        let own_workers = 0
        let bgColor = "gray.700"
        if (!hiddenProgress) {
            const values = [workers.BLUE!, workers.GREEN!, workers.RED!, workers.YELLOW!]
            maxValue = Math.max(...values)
            for (let faction of Object.values(FactionsWithoutNeutral)) {
                const progress = workers[faction]!
                factions.push({
                    faction: faction,
                    progress: progress,
                    color: faction,
                })
            }
            factions.sort((a, b) => (a.progress < b.progress ? 1 : b.progress < a.progress ? -1 : 0))
            bgColor = factions[0].faction
        } else if (userStore.player) {
            own_workers = workers[userStore.player!.faction as unknown as FactionsWithoutNeutral]!
        }

        // count all workers
        const allWorkers = factions.reduce((acc, faction) => {
            acc += faction.progress
            return acc
        }, 0)

        const countdownComplete = () => {
            forceUpdate()

            setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ["projects"] })
            }, 5000)
        }

        const now = new Date()
        const endDateDate = new Date(endDate)

        useEffect(() => {
            const interval = setInterval(() => {
                if (now > endDateDate) {
                    forceUpdate()
                    clearInterval(interval)
                }
            }, 1000)
            return () => clearInterval(interval)
        }, [])

        const disabled = now > endDateDate

        return (
            <Card>
                <CardHeader bgColor={bgColor}>
                    <HStack justifyContent={"space-between"}>
                        <Heading ps="2" size="md" alignSelf={"center"}>
                            {t(`projects.${name}.title`)}
                        </Heading>
                        <Text pe="2">
                            Ends in <Countdown date={endDate} daysInHours onComplete={countdownComplete} />
                        </Text>
                    </HStack>
                </CardHeader>

                <CardBody>
                    {!hiddenProgress && (
                        <Box borderLeft="1px solid" borderColor="orange.600">
                            {allWorkers > 0 ? (
                                <>
                                    {factions.map((faction) => (
                                        <AnimatedBox layout key={faction.faction}>
                                            <HStack gap={0}>
                                                <Progress
                                                    value={faction.progress / maxValue}
                                                    showText={false}
                                                    total={1}
                                                    progressColor={faction.color}
                                                    height={"10px"}
                                                    border="0"
                                                />
                                                <Box
                                                    minW="80px"
                                                    textAlign={"right"}
                                                    whiteSpace={"nowrap"}
                                                    borderLeft="1px solid"
                                                    borderColor="orange.600"
                                                >
                                                    <Text>{faction.progress}</Text>
                                                </Box>
                                            </HStack>
                                        </AnimatedBox>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <Text ps="4">No workers sent yet.</Text>
                                </>
                            )}
                        </Box>
                    )}
                    {hiddenProgress && (
                        <Stack mb="2">
                            <Text>
                                Your faction's workers : <Number value={own_workers} />
                            </Text>
                            <Text size="sm">You can't see others factions's progress.</Text>
                        </Stack>
                    )}

                    <ModList value={baseEffects} />

                    {Object.keys(gain).length > 0 && (
                        <Center fontSize="2xl">
                            <Resources {...gain} cost={false}></Resources>
                        </Center>
                    )}

                    <HStack justifyContent={"center"}>
                        <Box>
                            <Text>
                                Send <GameIcon name={ResourceType.WORKER} /> workers{" "}
                            </Text>
                        </Box>
                        <SimpleGrid columns={[2, 2, 2, 4]} gap={1}>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(1)
                                }}
                                isDisabled={resources.workers < 1 || disabled}
                            >
                                +1
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(10)
                                }}
                                isDisabled={resources.workers < 10 || disabled}
                            >
                                +10
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(100)
                                }}
                                isDisabled={resources.workers < 100 || disabled}
                            >
                                +100
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(Math.floor(resources.workers))
                                }}
                                isDisabled={resources.workers < 1 || disabled}
                            >
                                +{Math.floor(resources.workers)}
                            </Button>
                        </SimpleGrid>
                    </HStack>
                </CardBody>
            </Card>
        )
    },
    (prev, next) =>
        prev.id === next.id &&
        prev.workers.RED === next.workers.RED &&
        prev.workers.BLUE === next.workers.BLUE &&
        prev.workers.GREEN === next.workers.GREEN &&
        prev.workers.YELLOW === next.workers.YELLOW
)

export default EventProject
