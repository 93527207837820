import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect } from "react"
import LoginButtons from "../components/auth/LoginButtons"
import Cookies from 'js-cookie'
import { useStore } from "../store"
const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN

const Register: FunctionComponent = observer(() => {
    const {userStore} = useStore()
    useEffect(() => {
        Cookies.set('api_token', userStore.apiToken!, { expires: 1, domain: COOKIE_DOMAIN})
    }, [])
    return (
        <Box p="4">
            
            <Center>

                <Stack spacing={3} justifyContent={"center"} alignItems={"center"}>
                <Heading>Login</Heading>

                    <Text>Please log to keep track of your progress. You will not lose your current progress.</Text> 
                    <LoginButtons />
                </Stack>
            </Center>

        </Box>
    )
})

export default Register
