import { useMutation } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"
import { anonLogin } from "../api/player"
import { useStore } from "../store"
import { Box, Center, Heading, Link, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import Button from "../components/common/Button"
import Panel from "../components/common/Panel"
import LoginButtons from "../components/auth/LoginButtons"
import { useGame, useHQInfo, usePlayer } from "../hooks/query"
import Loading from "../components/common/Loading"
import DateComponent from "../components/common/DateComponent"
import { Leaderboard } from "../components/stats/Leaderboards"
import { Victory } from "../components/stats/Victory"
import Chat from "../components/chat/Chat"


const GameOver: FunctionComponent = () => {
    const { data: game } = useGame()
    // force store population
    usePlayer()

    if (!game)
        return <Loading />
    
    return (
        <Box p="4">
            <Center textAlign={"center"}>
                <Stack>
                    <Heading>Game over</Heading>


                    <Text>A faction earn 100 000 victory points or the castle has been captured.</Text>

                    <Text fontWeight={"bold"}>Winner : {game.winner}</Text>
                    <Text>Date : <DateComponent date={game.victoryDate} /></Text>

                    <Text>Congratulations everyone !</Text>

                    <Text><a href="https://discord.gg/DsKSmetnqV">Join the discord to be kept informed about the next round !</a></Text>
                </Stack>
            </Center>

            <Box mt="4" maxW="800px" mx="auto">
                <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={10}>
                    <Victory />
                    <Leaderboard />
                </SimpleGrid>
            </Box>
            <Chat />
        </Box>
    )
}

export default GameOver
