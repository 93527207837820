import { Box, Button, Checkbox, FormControl, FormHelperText, FormLabel, HStack, Heading, Input, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FormEvent, FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { updatePlayer } from "../api/player"
import { useStore } from "../store"
import i18next from 'i18next';


const Settings: FunctionComponent = observer(() => {

    const queryClient = useQueryClient()
    const { userStore, socketStore, settingsStore } = useStore()
    const [formState, setFormState] = useState("pending")
    const [formError, setFormError] = useState("")
    const [username, setUsername] = useState("")
    const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)
    const [accessibilityMode, setAccessibilityMode] = useState(false)
    const handleAccessibility = (event: React.ChangeEvent<HTMLInputElement>) => setAccessibilityMode(event.target.checked)

    const navigate = useNavigate()

    useEffect(() => {
        if (userStore.player?.username) {
            setUsername(userStore.player.username)
            setAccessibilityMode(settingsStore.accessibilityMode)
        }
    }, [userStore.player?.username])


    const changeUser = useMutation({
        mutationFn: () => updatePlayer({ username }),
        onMutate: () => {

        },
        onSuccess: (response) => {
            if (response.data.status === "ok") {
                setFormState("success")
                socketStore.addNotification({ id: Date.now().toString(), type: "message", title: 'notification.success', message: "Save with success" })
                queryClient.invalidateQueries({ queryKey: ["player"] })
                return navigate("/games")
            }
            else {
                setFormState("error")
                setFormError(response.data.reason)
                socketStore.addNotification({ id: Date.now().toString(), type: "error", title: 'notification.error', message: "Error while saving" })
            }
        }

    })

    const save = (event: FormEvent<HTMLFormElement>) => {
        setFormState("pending")
        setFormError("")
        event.preventDefault()
        changeUser.mutate()
        settingsStore.setAccessibilityMode(accessibilityMode)
    }

    const cancel = () => {
        return navigate(-1)
    }

    return (
        <Box p="4">

            <form onSubmit={save}>
                <Heading my="4">Settings</Heading>

                <Text variant="negative">{formError}</Text>

                {changeUser.isError && <Text variant="negative">An error occurred. Please retry later.</Text>}


                <FormControl my="4">
                    <FormLabel>Username</FormLabel>
                    <Input placeholder="Username" value={username} required minLength={4} maxWidth={"md"} color="orange.200" bgColor="orange.700" _focus={{ borderColor: "orange.500", boxShadow: "none" }} isDisabled={userStore.player?.temporary}
                        onChange={handleUsername}
                    />
                    {userStore.player?.temporary && <FormHelperText>You need to be registered to change your username.</FormHelperText>}
                </FormControl>

                <FormControl my="4">
                    <HStack alignItems={"center"}>
                        <Checkbox isChecked={accessibilityMode} onChange={handleAccessibility}>Accessibility</Checkbox>
                    </HStack>
                    <FormHelperText color="orange.200">Tick this to enable accessibility features.</FormHelperText>
                </FormControl>


                <HStack justifyContent={"end"} my="4">
                    <Button onClick={() => cancel()} colorScheme="red">Cancel</Button>
                    <Button
                        type='submit'
                        isLoading={changeUser.isPending}>
                        Save
                    </Button>
                </HStack>
            </form>
        </Box>
    )
})

export default Settings
