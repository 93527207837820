import { Box, Center, HStack, Heading, Input, Link, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FunctionComponent, useState } from "react"
import Loading from "../components/common/Loading"
import { useGame, usePlayer } from "../hooks/query"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router"
import {
    getGroup,
    getInvitations,
    groupAccept,
    groupDecline,
    groupInvite,
    groupKick,
    groupLeave,
    searchUser,
} from "../api/player"
import Button from "../components/common/Button"
import Panel from "../components/common/Panel"
import { Link as ReachLink } from "react-router-dom"
import Chat from "../components/chat/Chat"

const GroupPage: FunctionComponent = () => {
    const player = usePlayer()
    const queryClient = useQueryClient()
    const [querySearch, setQuerySearch] = useState("")
    const [error, setError] = useState("")

    const { data, isPending } = useQuery({
        queryKey: ["group"],
        queryFn: getGroup,
        staleTime: 0,
    })

    const { data: invitations } = useQuery({
        queryKey: ["invitations"],
        queryFn: getInvitations,
        staleTime: 0,
    })

    const { data: players, isLoading: isPendingSearch } = useQuery({
        queryKey: ["players", querySearch],
        queryFn: () => searchUser(querySearch),
        enabled: querySearch.length > 2,
    })

    const inviteMutation = useMutation({
        mutationFn: (playerId: number) => groupInvite(playerId),
        onSuccess: (data) => {
            if (data.status == "error") {
                setError(data.reason!)
            }
            queryClient.invalidateQueries({ queryKey: ["group"] })
        },
    })
    const kickMutation = useMutation({
        mutationFn: (playerId: number) => groupKick(playerId),
        onSuccess: (data) => {
            if (data.status == "error") {
                setError(data.reason!)
            }
            queryClient.invalidateQueries({ queryKey: ["group"] })
        },
    })
    const leaveMutation = useMutation({
        mutationFn: () => groupLeave(),
        onSuccess: (data) => {
            if (data.status == "error") {
                setError(data.reason!)
            }
            queryClient.invalidateQueries({ queryKey: ["group"] })
        },
    })
    const search = (e: any) => {
        e.preventDefault()
    }
    const invite = (playerId: number) => {
        inviteMutation.mutate(playerId)
        setQuerySearch("")
    }
    const kick = (playerId: number) => {
        kickMutation.mutate(playerId)
    }
    const leave = () => {
        leaveMutation.mutate()
    }

    const acceptMutation = useMutation({
        mutationFn: (invitationId: number) => groupAccept(invitationId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["group"] })
            queryClient.invalidateQueries({ queryKey: ["invitations"] })
            queryClient.invalidateQueries({ queryKey: ["player"] })
        },
    })

    const declineMutation = useMutation({
        mutationFn: (invitationId: number) => groupDecline(invitationId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["invitations"] })
            queryClient.invalidateQueries({ queryKey: ["player"] })
        },
    })

    return (
        <Box p="4">
            <Heading as="h1">Group</Heading>

            <Text variant="negative">{error}</Text>

            {invitations && (
                <Box maxWidth={600}>
                    {invitations.map((invitation) => (
                        <Box
                            key={invitation.id}
                            border="1px solid"
                            borderRadius={"sm"}
                            borderColor="orange.800"
                            m="1"
                            p="1"
                            bgColor="orange.700"
                        >
                            <HStack justifyContent={"space-between"}>
                                <Text fontSize="md">
                                    <Link as={ReachLink} to={`/player/${invitation.leader}`}>
                                        {invitation.leader}
                                    </Link>{" "}
                                    invited you to join their group.
                                </Text>
                                <Button
                                    onClick={() => {
                                        acceptMutation.mutate(invitation.id)
                                    }}
                                    confirm={true}
                                    colorScheme="green"
                                >
                                    Accept
                                </Button>
                                <Button
                                    onClick={() => {
                                        console.log(invitation.id)
                                        declineMutation.mutate(invitation.id)
                                    }}
                                    confirm={true}
                                    colorScheme="red"
                                >
                                    Decline
                                </Button>
                            </HStack>
                        </Box>
                    ))}
                </Box>
            )}

            <Panel variant="withBorder" maxWidth={500} overflow="auto">
                <form onSubmit={search}>
                    <Text>You can search player by username to invite them.</Text>

                    <Input
                        placeholder="Search"
                        name="search"
                        value={querySearch}
                        onChange={(e) => {
                            setQuerySearch(e.target.value)
                        }}
                    />
                    <Box position="relative">
                        {querySearch.length > 2 && (
                            <Box position="absolute" top="0" left="0" bgColor="orange.800" p="1" zIndex="10">
                                {isPendingSearch && <Loading size="xs" />}
                                {players &&
                                    players.map((player) => (
                                        <Text
                                            _hover={{ bgColor: "orange.700" }}
                                            p="1"
                                            onClick={() => invite(player.id)}
                                            key={player.id}
                                        >
                                            {player.username}
                                        </Text>
                                    ))}
                                {players && players.length === 0 && <Text>No players found.</Text>}
                            </Box>
                        )}
                    </Box>
                </form>
            </Panel>

            {data === null && <Text>You do not belong to a group.</Text>}
            {data !== null && (
                <Box maxW="800px">
                    <Text>Members of group</Text>

                    <Box w="full">
                        {data?.members.map((member) => (
                            <Box
                                key={member.id}
                                border="1px solid"
                                borderRadius={"sm"}
                                borderColor="orange.800"
                                m="1"
                                p="1"
                                bgColor="orange.700"
                            >
                                <HStack justifyContent={"space-between"}>
                                    <Box>
                                        <Text fontSize="xl">
                                            <Link 
                                            as={ReachLink} 
                                            to={`/player/${member.username}`}
                                            color="orange.300">
                                                {member.username}
                                            </Link>
                                        </Text>
                                        <Text fontSize="sm" color="orange.200">
                                            {member.isLeader
                                                ? "Leader"
                                                : member.pending
                                                ? "Pending invitation"
                                                : "Member"}
                                        </Text>
                                    </Box>

                                    {data.isLeader && !member.isLeader && (
                                        <Button
                                            onClick={() => {
                                                kick(member.id)
                                            }}
                                            confirm={true}
                                            colorScheme="red"
                                        >
                                            {member.pending ? "Cancel" : "Kick"}
                                        </Button>
                                    )}
                                </HStack>
                            </Box>
                        ))}

                        <Button
                            onClick={() => {
                                leave()
                            }}
                            confirm={true}
                            colorScheme="red"
                        >
                            Leave group
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default GroupPage
