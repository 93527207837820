import {
    Box,
    Icon,
    Portal,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react"
import { FunctionComponent, useState, useEffect } from "react"
import Stats from "./Stats"
import { useGameId, useHQInfo, useIsUnlocked } from "../hooks/query"
import { useIsMobile } from "../hooks/utils"
import { UnlockType } from "../types"
import HQ from "./HQ"
import World from "./World"
import { GiCheckboxTree, GiWorld } from "react-icons/gi"
import { RiHomeFill } from "react-icons/ri"
import { IoIosStats } from "react-icons/io"
import { GiAchievement } from "react-icons/gi"
import { GoCheckbox } from "react-icons/go"
import AchievementPage from "./AchievementPage"
import Lazy from "../components/common/Lazy"
import { useLocation, useNavigate, useNavigation } from "react-router-dom"
import SpecializationPage from "./SpecializationPage"
import Projects from "./Projects"
import { QuestBubble } from "../components/quests/QuestBubble"
import { BsChatSquareTextFill } from "react-icons/bs"
import ChatPage from "./ChatPage"
import { useStore } from "../store"
import { observer } from "mobx-react-lite"

const ROUTES = {
    0: "hq",
    1: "world",
    2: "spec",
    3: "projects",
    4: "stats",
    5: "achievements",
    6: "chat",
}

// revert the routes to get the index from the url
const ROUTES_REVERT = Object.fromEntries(
    Object.entries(ROUTES).map(([k, v]) => [v, parseInt(k, 10)])
)

const Menu: FunctionComponent = observer(() => {
    const showWorld = useIsUnlocked(UnlockType.UI_WORLD)
    const showProjects = useIsUnlocked(UnlockType.UI_PROJECTS)
    const showSpec = useIsUnlocked(UnlockType.UI_SPECIALIZATION)
    const { chatStore } = useStore()

    return (
        <TabList id="main-menu" boxShadow={"0px -1px 10px black"}>
            <Tab aria-label="Village">
                <Icon as={RiHomeFill} me="1" />
                <Box display={["none", "none", "inline-block"]} as="span">
                    Village
                </Box>
            </Tab>
            <Tab
                aria-label="World map"
                display={(showWorld && "flex") || "none"}
            >
                <Icon as={GiWorld} me="1" />
                <Box display={["none", "none", "inline-block"]}>World</Box>
            </Tab>
            <Tab
                aria-label="Specialization"
                display={(showSpec && "flex") || "none"}
            >
                <Icon as={GiCheckboxTree} me="1" />
                <Box display={["none", "none", "inline-block"]}>Spec</Box>
            </Tab>
            <Tab
                aria-label="Projects"
                display={(showProjects && "flex") || "none"}
            >
                <Icon as={GoCheckbox} me="1" />
                <Box display={["none", "none", "inline-block"]}>Projects</Box>
            </Tab>
            <Tab
                aria-label="Statistics"
                display={(showWorld && "flex") || "none"}
            >
                <Icon as={IoIosStats} me="1" />
                <Box display={["none", "none", "inline-block"]}>Stats</Box>
            </Tab>
            <Tab aria-label="Achievements">
                <Icon as={GiAchievement} me="1" />
                <Box display={["none", "none", "inline-block"]}>
                    Achievements
                </Box>
            </Tab>
            <Tab aria-label="Chat">
                <Icon as={BsChatSquareTextFill} me="1" />
                <Box display={["none", "none", "inline-block"]}>Chat</Box>
                {chatStore.hasUnreadMessages() && (
                    <Box
                        display={"inline-block"}
                        borderRadius="50%"
                        width="8px"
                        height="8px"
                        bgColor="red.800"
                        position="relative"
                        top="-6px"
                        left="0"
                    ></Box>
                )}
            </Tab>
        </TabList>
    )
})

const Game: FunctionComponent = () => {
    // set current tab according to url
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [tabIndex, setTabIndex] = useState(ROUTES_REVERT[pathname] || 0)
    const gameId = useGameId()
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
        navigate(`/play/${gameId}/${ROUTES[index as 0 | 1 | 2 | 3 | 4 | 5 | 6]}/`)
    }

    const location = useLocation()
    useEffect(() => {
        const lastPart = location.pathname.split("/")[3]
        console.log(lastPart)
        ROUTES_REVERT[lastPart] &&
            setTabIndex(ROUTES_REVERT[lastPart])
    }, [location])

    return (
        <Box>
            <Tabs
                index={tabIndex}
                variant="main"
                isLazy
                lazyBehavior="keepMounted"
                onChange={handleTabsChange}
            >
                {/* {!isMobile && <Menu />} */}

                <TabPanels>
                    <TabPanel>
                        <HQ />
                    </TabPanel>
                    <TabPanel p="0">
                        <World />
                    </TabPanel>
                    <TabPanel>
                        <SpecializationPage />
                    </TabPanel>
                    <TabPanel>
                        <Lazy active={tabIndex === 3}>
                            <Projects />
                        </Lazy>
                    </TabPanel>
                    <TabPanel>
                        <Stats />
                    </TabPanel>
                    <TabPanel>
                        <Lazy active={tabIndex === 5}>
                            <AchievementPage />
                        </Lazy>
                    </TabPanel>
                    <TabPanel padding="0">
                        <ChatPage />
                    </TabPanel>
                </TabPanels>

                {/* {isMobile && ( */}
                <Portal>
                    <Box
                        position={"fixed"}
                        bottom="0"
                        left="0"
                        bgColor="orange.300"
                        w="full"
                    >
                        <Menu />
                    </Box>
                </Portal>
                {/* )} */}
            </Tabs>
        </Box>
    )
}

export default Game
