import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { upgradeHQ } from "../../api/game"
import { useGameId, useHQInfo } from "../../hooks/query"
import Resources from "../resources/Resources"
import { UISpendResources, hasEnoughResources } from "../../hooks/utils"
import Button from "../common/Button"
import { ImArrowUp } from "react-icons/im"
import { Wrap, WrapItem } from "@chakra-ui/react"

const HQLevelUp: FunctionComponent = () => {
    const { data: info } = useHQInfo()
    const gameId = useGameId()

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: () => upgradeHQ(gameId),
        onMutate: () => {
            UISpendResources({ queryClient, resources: info?.hq.upgradeCost! })
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["hqInfo"] }),
    })


    const enoughResources = info?.hq ? hasEnoughResources(info?.hq.upgradeCost) : false

    return (
        <Wrap align={"center"} justify={"center"}>
            <WrapItem >
                <Resources {...info?.hq.upgradeCost} />
            </WrapItem>
            <WrapItem>

                <Button
                    leftIcon={<ImArrowUp />}
                    onClick={() => {
                        mutation.mutate()
                    }}
                    isDisabled={!enoughResources}
                    aria-label="Upgrade HQ"
                >
                    Upgrade
                </Button>
            </WrapItem>

        </Wrap>
    )
}

export default HQLevelUp
