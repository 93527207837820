import { action, autorun, has, makeObservable, observable, runInAction } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { FACTIONS_LIST, Factions } from "../types"
import ReconnectingWebSocket from "reconnecting-websocket"
import { WS_BASE_URL } from "../config"

export interface Message {
    id: number
    player: {
        username: string
        faction: Factions
    }
    content: string
    created_at: number // timestamp
}

export interface Channel {
    id: string
    name: string
    socket: ReconnectingWebSocket
    messages: Message[]
    loaded: boolean
    lastReadMessage: number | null
    hasUnreadMessages: boolean
}

export default class ChatStore extends BaseStore {

    channels: Channel[] = []
    // hasUnreadMessages = false
    chatIsVisible = false
    activeChannel: number = 0
    isChatVisible = false


    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            channels: observable,
            hasUnreadMessages: observable,
            chatIsVisible: observable,
            activeChannel: observable,
            isChatVisible: observable,
            init: action,
            fetchMessages: action,
            markAsRead: action,
            setActiveChannel: action,
            setIsChatVisible: action,
        })

        autorun(() => {
            if (this.rootStore.userStore.player?.gameId)
                this.init()
        })


    }

    init() {
        const gameId = this.rootStore.userStore.player!.gameId
        const faction = this.rootStore.userStore.player!.faction
        const enemyFactions = FACTIONS_LIST.filter(f => f !== faction)
        // get last from local storage
        const storage = localStorage.getItem("chat")
        const readInfos = storage ? JSON.parse(storage) : []

        const channels: Channel[] = [{
            id: `${gameId}_general`,
            name: 'General',
            socket: new ReconnectingWebSocket(`${WS_BASE_URL}chat/${gameId}_general`, ['Token', `${this.rootStore.userStore.apiToken}`]),
            loaded: false,
            messages: [],
            lastReadMessage: readInfos.find((c: Channel) => c.id === `${gameId}_general`)?.lastUnreadMessage || null,
            hasUnreadMessages: false
        },
        {
            id: `${gameId}_${faction}`,
            name: faction,
            socket: new ReconnectingWebSocket(`${WS_BASE_URL}chat/${gameId}_${faction}`, ['Token', `${this.rootStore.userStore.apiToken}`]),
            loaded: false,
            messages: [],
            lastReadMessage: readInfos.find((c: Channel) => c.id === `${gameId}_${faction}`)?.lastUnreadMessage || null,
            hasUnreadMessages: false
        },
        ]
        channels.push(...enemyFactions.map(f => {
            const factions = [faction, f].sort().join("_")
            return {
                id: `${gameId}_${faction}_${f}`,
                name: `With ${f}`,
                socket: new ReconnectingWebSocket(`${WS_BASE_URL}chat/${gameId}_${factions}`, ['Token', `${this.rootStore.userStore.apiToken}`]),
                loaded: false,
                messages: [],
                lastReadMessage: readInfos.find((c: Channel) => c.id === `${gameId}_${faction}_${f}`)?.lastUnreadMessage || null,
                hasUnreadMessages: false
            }
        }))
        this.channels = channels

        this.channels.forEach(c => {
            c.socket.onopen = () => {
                this.fetchMessages(c.id)
            }
        })
    }

    getChannel(channelId: string) {
        return this.channels.find(c => c.id === channelId)
    }

    fetchMessages(channelId: string) {
        const channel = this.getChannel(channelId)
        if (channel!.socket) {
            channel!.socket.onmessage = (e) => {
                const data = JSON.parse(e.data)
                const type = data.type
                if (type == "initial" && channel!.loaded) return
                const newMessages = data.messages as Message[]
                runInAction(() => {
                    
                    if (type == "initial") {
                        channel!.messages = newMessages
                        channel!.loaded = true
                        // check if we have recent messages
                        const lastRead = channel!.lastReadMessage
                        if (newMessages.length && newMessages[newMessages.length - 1].id > lastRead!) {
                            channel!.hasUnreadMessages = true
                        }
                    }
                    else {
                        channel!.messages = [...channel!.messages, ...newMessages]
                        // this.hasUnreadMessages = true
                        if (this.getActiveChannel().id == channel!.id && this.isChatVisible) {
                            channel!.hasUnreadMessages = false
                            this.markAsRead(channelId, true)
                        }
                        else {
                            channel!.hasUnreadMessages = true
                        }
                    }
                })

            }
        }
    }

    // setChatIsVisible(visible: boolean) {
    //     this.chatIsVisible = visible
    //     if (visible) {
    //         this.hasUnreadMessages = false
    //     }
    // }

    markAsRead(channelId: string | undefined, removeLastRead = false) {
        if (channelId) {
            const channel = this.getChannel(channelId)
            channel!.hasUnreadMessages = false
            if (removeLastRead && channel!.messages.length)
                channel!.lastReadMessage = channel!.messages[channel!.messages.length - 1].id
            this.storeToLocalStorage()
        }
        // else
        // this.hasUnreadMessages = false

    }

    setActiveChannel(index: number) {
        this.activeChannel = index
        this.channels[index].hasUnreadMessages = false
    }

    getActiveChannel() {
        return this.channels[this.activeChannel]
    }

    /** send a message to a channel */
    sendMessage(channelId: string, message: string) {
        const channel = this.getChannel(channelId)
        if (channel!.socket) {
            channel!.socket.send(JSON.stringify({ message }))
        }
    }

    hasUnreadMessages() {
        // check every channels to see if there are unread messages
        return this.channels.some(c => c.hasUnreadMessages)
    }

    setIsChatVisible(visible: boolean) {
        this.isChatVisible = visible
    }

    storeToLocalStorage() {
        const channels = this.channels.map(c => {
            return {
                id: c.id,
                lastUnreadMessage: c.lastReadMessage,
            }
        })
        localStorage.setItem("chat", JSON.stringify(channels))
    }


}
