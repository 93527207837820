
import { Button as CButton, ButtonProps as CButtonProps, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text, Tooltip } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Wrap from "./Wrap"
import { useIsMobile } from "../../hooks/utils"


interface ButtonProps extends CButtonProps {
    children: React.ReactNode
    onClick?: () => void
    confirm?: boolean
    tooltip?: string
}
const Button: FunctionComponent<ButtonProps> = ({ children, onClick, confirm = false, tooltip, ...buttonProps }) => {
    // for confirm dialog
    const { isOpen, onOpen, onClose } = useDisclosure()
    const isMobile = useIsMobile()

    const click = () => {
        if (onClick) {
            if (confirm) {
                onOpen()
            }
            else onClick()
        }
    }

    return (
        <>
            <Wrap if={!!tooltip && !isMobile} with={children => <Tooltip label={tooltip}>{children}</Tooltip>}>
                <CButton {...buttonProps} onClick={click} role="group">
                    <Text as="span" _groupHover={{ transform: !buttonProps.isDisabled ? 'translate3d(1px,1px,0)' : '' }}>
                        {children}
                    </Text>
                </CButton>
            </Wrap>
            {confirm &&
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirm</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure ?
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='gray' mr={3} onClick={(onClose)}>
                                No
                            </Button>
                            <Button colorScheme="red" onClick={() => onClick!()}>Yes</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
        </>
    )
}

export default Button