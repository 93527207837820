import { Box, Flex, HStack, Stack, Text, Tooltip } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { Effect } from "../../api/game"
import { useEffects, useIsUnlocked, useResources } from "../../hooks/query"
import { useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import { ResourceType, UnlockType } from "../../types"
import GameIcon from "../common/GameIcon"
import Number from "../common/Number"

interface CurrentResourcesProps {
    collapsable?: boolean
    simple?: boolean
}
const CurrentResources: FunctionComponent<CurrentResourcesProps> = ({ collapsable = false, simple = false }) => {
    const { wood, iron, soldiers, workers, guardian, knight } = useResources()
    const showSoldier = useIsUnlocked(UnlockType.UI_WORLD)
    const showGuardian = useIsUnlocked(UnlockType.UI_GUARDIAN)
    const showKnight = useIsUnlocked(UnlockType.UI_KNIGHT)
    const showWorker = useIsUnlocked(UnlockType.UI_PROJECTS)
    const { data: effects } = useEffects()
    const isMobile = useIsMobile()

    return (
        <Box
            display="flex"
            justifyContent={collapsable ? "flex-start" : "center"}
            w="100%"
            gap="1"
            pb="1"
            flexWrap={"wrap"}
        >
            {wood !== undefined && effects && (
                <>
                    <CurrentResource
                        name={ResourceType.WOOD}
                        value={wood}
                        production={effects.production.wood!}
                        storage={effects.storage.wood!}
                        collapsable={collapsable}
                        simple={simple}
                        showProduction={!isMobile || collapsable}
                    />
                    <CurrentResource
                        name={ResourceType.IRON}
                        value={iron}
                        production={effects?.production.iron!}
                        storage={effects?.storage.iron!}
                        collapsable={collapsable}
                        simple={simple}
                        showProduction={!isMobile || collapsable}
                    />
                    {showSoldier && (
                        <CurrentResource
                            name={ResourceType.SOLDIER}
                            value={soldiers}
                            production={effects?.production.soldiers!}
                            storage={effects?.storage.soldiers!}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                        />
                    )}
                    {showWorker && (
                        <CurrentResource
                            name={ResourceType.WORKER}
                            value={workers}
                            production={effects?.production.workers!}
                            storage={effects?.storage.workers!}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                        />
                    )}
                    {showGuardian && (
                        <CurrentResource
                            name={ResourceType.GUARDIAN}
                            value={guardian}
                            percentage={true}
                            production={effects?.production.guardian!}
                            unique={true}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                        />
                    )}
                    {showKnight && (
                        <CurrentResource
                            name={ResourceType.KNIGHT}
                            value={knight}
                            percentage={true}
                            production={effects?.production.knight!}
                            unique={true}
                            collapsable={collapsable}
                            simple={simple}
                            showProduction={!isMobile || collapsable}
                        />
                    )}
                </>
            )}
        </Box>
    )
}

interface CurrentResourceProps {
    name: ResourceType
    value: number
    production: Effect
    storage?: Effect
    unique?: boolean
    collapsable?: boolean
    simple?: boolean
    percentage?: boolean
    showProduction?: boolean
}

const minutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = (minutes % 60).toFixed(0)
    // add leading 0
    if (remainingMinutes.length == 1) {
        return `${hours}h0${remainingMinutes}m`
    }
    return `${hours}h${remainingMinutes}m`
}

interface SupportIconProps {
    name: ResourceType
}
const SupportIcon: FunctionComponent<SupportIconProps> = observer(({ name }) => {
    const { mapStore } = useStore()
    const position = mapStore.getCurrentSupportPosition()

    const onClick = () => {
        if (position) mapStore.setSelectedTile(position.x, position.y)
    }

    return (
        <Tooltip label={position ? "Active on the map" : "Inactive"}>
            <Box cursor={"pointer"}>
                <GameIcon name={name} scale={1.5} onClick={onClick} inactive={!position} />
            </Box>
        </Tooltip>
    )
})

const CurrentResource: FunctionComponent<CurrentResourceProps> = observer(
    ({
        name,
        value,
        production,
        storage,
        collapsable = false,
        simple = false,
        unique = false,
        percentage = false,
        showProduction = true,
    }) => {
        // const { isOpen, onToggle } = useDisclosure()

        // const format = (value: number) => {
        //     return value.toFixed(1)
        // }
        const fullValue = unique ? 100 : storage?.total || 0
        const isFull = value >= fullValue
        const numOfFractionDigits = ["soldiers", "workers"].includes(name) ? 2 : 1
        const timeBeforeFull = production.total ? Math.max(fullValue - value, 0) / (production.total * 3) : 0

        if (simple) {
            return (
                <HStack
                    border="1px solid"
                    borderColor="orange.900"
                    px="1"
                    bgColor="orange.500"
                    borderRadius={"sm"}
                    boxShadow="md"
                >
                    <HStack fontSize="sm" alignItems={"center"} gap="0">
                        {unique ? <SupportIcon name={name} /> : <GameIcon name={name} scale={1.5} />}
                        <Number value={value} animated percentage={percentage} color={isFull ? "red.700" : "auto"} />
                    </HStack>

                    {showProduction && (
                        <Text fontSize="xs">
                            {" "}
                            (+
                            <Number value={production.total} numOfFractionDigits={numOfFractionDigits} animated />)
                        </Text>
                    )}
                </HStack>
            )
        }
        if (!collapsable) {
            return (
                <Box
                    border="1px solid"
                    borderColor="orange.900"
                    p="1"
                    bgColor="orange.500"
                    borderRadius={"sm"}
                    boxShadow={"md"}
                >
                    <Flex alignItems={"center"}>
                        <Box p="2">{unique ? <SupportIcon name={name} /> : <GameIcon name={name} scale={1.5} />}</Box>

                        <Stack gap="0" justifyContent={"center"} alignItems={"center"}>
                            <Box>
                                <Number
                                    value={value}
                                    animated
                                    percentage={percentage}
                                    color={isFull ? "red.700" : "auto"}
                                />

                                {storage && (
                                    <Text size="sm" as="span">
                                        /
                                        <Number fontSize="sm" animated value={storage.total} />
                                    </Text>
                                )}
                            </Box>

                            <Text fontSize="sm">
                                <Number
                                    value={production.total}
                                    showPlus
                                    numOfFractionDigits={numOfFractionDigits}
                                    animated
                                />
                                /<Tooltip label="Time before full">{minutesToTime(timeBeforeFull)}</Tooltip>
                            </Text>
                        </Stack>
                    </Flex>
                </Box>
            )
        } else {
            return (
                <Box>
                    {/* <Button variant="ghost" onClick={onToggle}> */}
                    <Text>
                        <HStack alignItems={"center"}>
                            <GameIcon name={name} />
                            <Box>
                                <Stack gap={0}>
                                    <Box>
                                        <Number value={value} fontSize="xl" percentage={percentage} />

                                        {storage && (
                                            <>
                                                {" "}
                                                / <Number value={storage.total} />
                                            </>
                                        )}
                                    </Box>

                                    {showProduction && (
                                        <Box>
                                            (
                                            <Number
                                                value={production.total}
                                                showPlus
                                                numOfFractionDigits={numOfFractionDigits}
                                            />
                                            /<Tooltip label="Time before full">{minutesToTime(timeBeforeFull)}</Tooltip>
                                            )
                                        </Box>
                                    )}
                                </Stack>
                            </Box>
                        </HStack>
                    </Text>
                    {/* <Icon aria-label="Show more" as={ChevronDownIcon} /> */}
                    {/* </Button> */}
                    {/* <Collapse in={isOpen} animateOpacity>
                    <>
                        <Heading as="h5">Production</Heading>
                        <CurrentResourceDetails
                            details={production.details}
                            total={production.total}
                        />
                        {storage && <>
                            <Heading as="h5">Storage</Heading>
                            <CurrentResourceDetails
                                details={storage.details}
                                total={storage.total}
                            />
                        </>
                        }
                    </>
                </Collapse> */}
                </Box>
            )
        }
    }
)

// interface CurrentResourceDetailsProps {
//     details: ResourceFrom[]
//     total: number
// }

// const CurrentResourceDetails: FunctionComponent<
//     CurrentResourceDetailsProps
// > = ({ details, total }) => {
//     return (
//         <Box>
//             <Text>Details</Text>
//             <List>
//                 {details.map((detail) => (
//                     <ListItem key={detail.from}>
//                         <HStack justifyContent={"space-between"}>
//                             <Text as="span">{detail.from} : </Text>
//                             <Text as="span">
//                                 {detail.base && <Number value={detail.base} />}
//                                 {detail.bonus && <Number value={detail.bonus} percentage />}
//                             </Text>
//                         </HStack>
//                     </ListItem>
//                 ))}
//                 <ListItem>
//                     <HStack justifyContent={"space-between"}>
//                         <Text as="span">Total :</Text>
//                         <Number value={total} />
//                     </HStack>
//                 </ListItem>
//             </List>
//         </Box>
//     )
// }

export default CurrentResources
