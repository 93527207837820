import { Factions } from "../types"
import { BaseResponse, authApi } from "./base"


export enum GameStatus {
    PLAYING = "PLAYING",
    COMPLETED = "COMPLETED",
    LOBBY = "LOBBY"
}

export enum GameType {
    STANDARD = "STANDARD",
    FLASH = "FLASH"
}

export interface Game {
    id: number
    status: GameStatus
    type: GameType
    winner: Factions
    winning: Factions
    victoryDate: Date
    end: Date
    points: {
        [Factions.RED]: number
        [Factions.BLUE]: number
        [Factions.GREEN]: number
        [Factions.YELLOW]: number
    }
    numberOfPlayers: number
    isOpen: boolean
    participating: boolean
    maxPlayers: number
    
}

export async function getGame(gameId: number): Promise<Game> {
    const gameInfo = await authApi.get(`games/${gameId}/get`)
    return gameInfo.data
}

export async function getGames(): Promise<Game[]> {
    const gameInfo = await authApi.get('games/list')
    return gameInfo.data
}

export async function joinGame(gameId: number): Promise<BaseResponse> {
    const gameInfo = await authApi.post(`games/${gameId}/join`)
    return gameInfo.data
}

export async function leaveLobby(gameId: number): Promise<BaseResponse> {
    const gameInfo = await authApi.post(`games/${gameId}/leave`)
    return gameInfo.data
}