import { Card, CardBody, CardHeader, Flex, Heading, List, ListIcon, ListItem, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { useGame } from "../../hooks/query"
import { FactionStat, Factions, ResourceType } from "../../types"
import { getKeys } from "../../utils"
import GameIcon from "../common/GameIcon"
import Number from "../common/Number"
import Progress from "../common/ProgressWithText"
import Resource from "../resources/Resource"
import { MdOutlineCropSquare } from "react-icons/md"

interface VictoryProps {}

export const Victory: FunctionComponent<VictoryProps> = ({}) => {
    const { data: game } = useGame()

    const victoryPoints = {
        [Factions.RED]: game?.points.RED || 0,
        [Factions.YELLOW]: game?.points.YELLOW || 0,
        [Factions.BLUE]: game?.points.BLUE || 0,
        [Factions.GREEN]: game?.points.GREEN || 0,
    }
    const victoryStats: FactionStat[] = []
    getKeys(victoryPoints).map((faction) => {
        let percentage = (victoryPoints[faction] * 100) / 100000
        victoryStats.push({
            faction: faction,
            percentage,
            value: victoryPoints[faction],
        })
    })

    victoryStats.sort(function (a, b) {
        return b.percentage - a.percentage
    })

    return (
        <Card variant="elevated">
            <CardHeader>
                <Heading size="md">Victory</Heading>
            </CardHeader>

            <CardBody>
                <Text fontSize="sm" mb="2">
                    Victory conditions :
                    <List>
                        <ListItem>
                            <ListIcon as={MdOutlineCropSquare} color="green.800" pos="relative" top="-2px" />
                            Be the first faction to reach 100 000 victory points.
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdOutlineCropSquare} color="green.800" pos="relative" top="-2px" />
                            Capture the castle and hold it for 12 hours.
                        </ListItem>
                    </List>
                </Text>
                <Heading size="sm" display={"flex"} alignItems={"center"}>
                    <GameIcon scale={1.5} mr="1" name={ResourceType.VICTORY_POINTS} />
                    Victory points
                </Heading>
                <Flex flexDirection={"column"} height={"full"} mt="4">
                    {victoryStats.map((stat) => (
                        <Progress
                            key={stat.faction}
                            value={stat.percentage}
                            total={100}
                            h="50px"
                            progressColor={stat.faction}
                        >
                            <Text fontSize="xl" p={1} as="span">
                                {stat.faction} : <Number value={stat.percentage} percentage /> (
                                <Number value={stat.value} />)
                            </Text>
                        </Progress>
                    ))}
                </Flex>
            </CardBody>
        </Card>
    )
}
