import { Box, Center, HStack, Input, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useRef, useEffect } from "react"
import { useStore } from "../../store"
import MessageComponent from "./Message"
import Button from "../common/Button"
import { useIsVisible, usePrevious } from "../../hooks/utils"
import { useNavigate } from "react-router-dom"
import Loading from "../common/Loading"

interface ChannelProps {
    channelId: string
}
const Channel: FunctionComponent<ChannelProps> = observer(({ channelId }) => {
    const { chatStore, mapStore } = useStore()
    const channel = chatStore.getChannel(channelId)
    const ref = useRef<HTMLInputElement>(null)
    const boxRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()

    const sendMessage = (e: React.FormEvent) => {
        e.preventDefault()
        chatStore.sendMessage(channelId, ref.current!.value)
        ref.current!.value = ""
        if (boxRef.current) boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
    }

    // scroll when new message is added
    useEffect(() => {
        // scroll to bottom if we're already at the bottom
        if (
            boxRef.current &&
            boxRef.current.scrollTop + boxRef.current.clientHeight + 100 > boxRef.current.scrollHeight
        ) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
        }
    }, [channel?.messages.length])

    // scroll to bottom at the start
    useEffect(() => {
        if (channel?.loaded && boxRef.current) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
        }
    }, [channel?.loaded])

    const handleMessageClick = (e: any) => {
        if (e.target.dataset?.x && e.target.dataset?.y) {
            mapStore.setSelectedTile(parseInt(e.target.dataset!.x), parseInt(e.target.dataset!.y))
            navigate(`/play/world`)
        }
    }

    const isVisible = useIsVisible(boxRef)
    const prevIsVisible = usePrevious(isVisible)

    useEffect(() => {
        if (!isVisible && prevIsVisible) {
            chatStore.markAsRead(channelId, true)
        }
        if (isVisible && boxRef.current) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
            setTimeout(() => ref.current?.focus(), 1)
        }
    }, [isVisible])

    return (
        <Box>
            <Box maxHeight={400} height={400} overflowY={"scroll"} ref={boxRef} onClick={handleMessageClick}>
                {!channel?.loaded && (
                    <Center height="full">
                        <Loading />
                    </Center>
                )}
                {channel?.messages.map((message, i) => (
                    <MessageComponent
                        key={message.id}
                        {...message}
                        channelId={channelId}
                        isLast={i === channel.messages.length - 1}
                    />
                ))}
            </Box>

            <form onSubmit={sendMessage}>
                <HStack gap="0">
                    <Input
                        type="text"
                        name="message"
                        ref={ref}
                        autoComplete="off"
                        color="orange.200"
                        bgColor="orange.700"
                        _focus={{
                            borderColor: "orange.500",
                            boxShadow: "none",
                        }}
                        borderRadius="sm"
                        autoFocus
                    />
                    <Button type="submit">Send</Button>
                </HStack>
            </form>
        </Box>
    )
})

export default Channel
