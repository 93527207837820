import { Box, Stack, Text, Tooltip } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { FactionTileInfo } from "../../../api/world"
import { Factions, SupportType } from "../../../types"
import GameIcon from "../../common/GameIcon"
import HelpText from "../../common/HelpText"
import { TileInfo } from "../../../store/MapStore"
import Number from "../../common/Number"
import DateComponent from "../../common/DateComponent"

interface SupplyProps {
    lostSupplyDate: Date
}
const Supply: FunctionComponent<SupplyProps> = ({ lostSupplyDate }) => {
    // compute number of hours since last supply
    let lostSupplyHours = 0
    let nextHour = new Date(lostSupplyDate)
    const now = new Date()
    if (lostSupplyDate) {
        lostSupplyHours = Math.floor((now.getTime() - lostSupplyDate.getTime()) / 1000 / 60 / 60)
        nextHour = nextHour.addHours(lostSupplyHours + 1)
    }

    return (
        <>
            {lostSupplyDate && (
                <Stack>
                    <Box fontSize="2xs">
                        {lostSupplyHours < 1 && (
                            <Text>
                                Supply line is cut. At <DateComponent showDate={false} date={nextHour} />, defense will
                                be reduced by 20%.
                            </Text>
                        )}
                        {lostSupplyHours >= 1 && (
                            <>
                                <Text>Supply line is cut and defense is reduced by 20%.</Text>
                                <Text>
                                    Every hour, 20% of this terrain soldiers will die until supply line is restored.
                                </Text>
                                <Text fontWeight={"bold"}>
                                    Next decay at <DateComponent showDate={false} date={nextHour} />
                                </Text>
                            </>
                        )}
                    </Box>

                    {/* <Text>
                        Hour since last supply : {lostSupplyHours}
                        Lost supply date : <DateComponent date={lostSupplyDate} />
                        Next hour : <DateComponent date={nextHour} />
                    </Text> */}
                </Stack>
            )}
        </>
    )
}

export default Supply
