import {
    Box,
    Stack,
    useDimensions,
    useSafeLayoutEffect,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import Phaser from "phaser"
import { FunctionComponent, useEffect, useLayoutEffect, useRef } from "react"
import { useConfig, useInvasionInfo } from "../../hooks/query"
import { useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import { gameConfig } from "./config"
import SelectedTile from "./SelectedTile"
import MapUI from "./MapUI"

// tilemap with layer : https://phaser.io/examples/v2/tilemaps/blank-tilemap

const Map: FunctionComponent = observer(() => {
    const { mapStore, socketStore } = useStore()
    const worldConfig = useConfig()
    const { data: invasionInfo } = useInvasionInfo()
    const mobile = useIsMobile()
    const boxRef = useRef<HTMLInputElement>(null)

    const dimensions = useDimensions(boxRef, false)

    // send config to game
    useEffect(
        function () {
            if (worldConfig && mapStore.booted) {
                window.game?.events.emit("config", worldConfig)
                // alert('send config')
                // if (emitted) game?.events.off("prestep", sendConfig)

                // window.game?.events.addListener(
                //     Phaser.Core.Events.FOCUS,
                //     () => {
                //         window.game?.input.enabled = true
                //     }
                // )
            }
        },
        [worldConfig, mapStore.booted]
    )

    useEffect(() => {
        // enable keyword capture on clicking on map + right panel
        // or disable it when clicking outside
        if (mapStore.booted) {
            const handleClick = (e: MouseEvent) => {
                const phaserDiv = document.getElementById("world")
                if (phaserDiv && e.target && phaserDiv.contains(e.target as Node)) {
                    window.game!.input!.keyboard!.enabled = true
                } else {
                    window.game!.input!.keyboard!.enabled = false
                }
            }
            window.addEventListener("click", handleClick)
            return () => {
                window.removeEventListener("click", handleClick)
            }
        }
    }, [mapStore.booted])

    useEffect(() => {
        if (invasionInfo && mapStore.booted) {
            window.game?.events.emit("invasionInfo", invasionInfo.world)
        }
    }, [invasionInfo?.generated, mapStore.booted])

    // remove focus on other element so we can focus on canvas
    useEffect(() => {
        const handleClick = () => {
            // check if not in menu
            const mainMenu = document.getElementById("main-menu")
            const navigation = document.getElementById("navigation")
            if (
                mainMenu?.contains(document.activeElement) ||
                navigation?.contains(document.activeElement)
            )
                (document.activeElement as HTMLElement).blur()
        }
        boxRef.current?.addEventListener("click", handleClick)
        return () => {
            boxRef.current?.removeEventListener("click", handleClick)
        }
    }, [])

    const startGame = () => {
        mapStore.setBooted(false)
        // console.log("boot game")
        // console.log(boxRef?.current)
        // console.log(dimensions)
        // console.log(dimensions)
        // console.log(document.getElementById("worldBox")?.getBoundingClientRect())
        if (!window.game?.isBooted && dimensions?.contentBox.width) {
            let width = 600
            let height =
                window.innerHeight -
                document.getElementById("navigation")!.getBoundingClientRect()
                    .height -
                document.getElementById("main-menu")!.getBoundingClientRect()
                    .height -
                document.getElementById("map-ui")!.getBoundingClientRect()
                    .height

            // console.log(height, document.getElementById("navigation")!.getBoundingClientRect()
            // .height, document.getElementById("main-menu")!.getBoundingClientRect()
            // .height, document.getElementById("map-ui")!.getBoundingClientRect()
            // .height, window.innerHeight)
            if (mobile) {
                // console.log(boxRef.current)
                // height = boxRef.current.getBoundingClientRect().height
                width = window.innerWidth - 12 // 10 = border size
                height -= 46 // 10 = border size
            } else {
                width = dimensions?.contentBox.width - 330
                height -= 40
            }
            const config = { ...gameConfig, height, width }
            window.game = new Phaser.Game(config)

            // game.events.on("prestep", sendConfig)
        }
    }

    useEffect(() => {
        startGame()
        // setTimeout(() => {
        //     startGame()
        // }, 3000)
        return () => {
            window.game?.destroy(true)
            window.game = null
            socketStore.stopWorldSocket()
            // TODO : destroy socket
        }
    }, [dimensions?.contentBox.width])

    return (
        <Box ref={boxRef} display={mobile ? "block" : "flex"} h="100%">
            <Stack
                border={[0, 0, "5px solid"]}
                borderColor="orange.200"
                gap="0"
            >
                <Box id="phaser"></Box>
                <MapUI />
            </Stack>
            <Box w="full">
                <SelectedTile />
            </Box>
        </Box>
    )
})

export default Map
