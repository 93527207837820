import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Resources, gatherResources, getConfig, getEffects, getHQInfo } from "../api/game"
import { getConnectedPlayers, getPlayer } from "../api/player"
import { getInvasionsInfo } from "../api/world"
import { PlayerResources, ResourceType, UnlockType } from "../types"
import { rootStore } from "../store"
import { getProjects } from "../api/projects"
import { queryClient } from "../query_client"
import { useParams } from "react-router-dom"
import { getGame } from "../api/games"

export const useGameId = () => {
    const params = useParams()
    const gameId = parseInt(params.gameId as string, 10)
    return gameId
}

export const useGatherResources = () => {
    const gameId = useGameId()
    // gather and get player resources
    const resources = useQuery({
        queryKey: ["gatherResources"],
        queryFn: () => gatherResources(gameId),
        refetchOnWindowFocus: true,
        staleTime: 20 * 1000,
        gcTime: 20 * 1000,
        refetchInterval: 20 * 1000,
        retry: 0,
    })
    return resources
}

export const usePlayer = () => {
    // get player info
    const gameId = useGameId()
    const player = useQuery({
        queryKey: ["player"],
        queryFn: () => getPlayer(gameId),
    })
    if (player.data?.username) {
        setTimeout(() => {
            rootStore.userStore.setPlayer(player.data)
        }, 1)
    }
    return player.data
}

export const useResources = (): PlayerResources => {
    // get player resources
    // get player resources
    const { data } = useGatherResources()

    const wood = data?.wood || 0
    const iron = data?.iron || 0
    const soldier = data?.soldiers || 0
    const worker = data?.workers || 0
    const guardian = data?.guardian || 0
    const knight = data?.knight || 0
    return {
        [ResourceType.WOOD]: wood,
        [ResourceType.IRON]: iron,
        [ResourceType.SOLDIER]: soldier,
        [ResourceType.WORKER]: worker,
        [ResourceType.GUARDIAN]: guardian,
        [ResourceType.KNIGHT]: knight,
    }
}

export const useHQInfo = () => {
    // get player hq info
    const gameId = useGameId()
    return useQuery({ queryKey: ["hqInfo"], queryFn: () => getHQInfo(gameId) })
}

export const useGame = () => {
    // get player game info
    const gameId = useGameId()
    return useQuery({ queryKey: ["game"], queryFn: () => getGame(gameId) })
}

export const useEffects = () => {
    // get all effects
    const gameId = useGameId()
    return useQuery({ queryKey: ["effects"], queryFn: () => getEffects(gameId), refetchInterval: 60 * 1000 })
}

export const useConfig = () => {
    const gameId = useGameId()
    const config = useQuery({
        queryKey: ["config"],
        queryFn: () => getConfig(gameId),
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: Infinity,
        refetchInterval: 0,
    })
    return config.data
}

export const useIsUnlocked = (name: UnlockType) => {
    // check if player has unlocked something
    const hqInfo = useHQInfo()
    if (!hqInfo.data) return false
    return hqInfo.data.unlocks.includes(name)
}

// export const useTileInfo = (x: number | undefined, y: number | undefined, enabled: boolean = true) => {
//     // get tile info
//     const tile = useQuery({
//         queryKey: ["tileInfo", x, y],
//         queryFn: () => getTileInfo(x!, y!),
//         enabled: !!(x !== undefined && y !== undefined) && enabled
//     })
//     return tile
// }

export const useInvasionInfo = () => {
    // get invasion info
    const gameId = useGameId()
    const world = useQuery({
        queryKey: ["worldInfo"],
        queryFn: () => getInvasionsInfo(gameId),
        // it's supposed to be handled by sockets
        refetchOnWindowFocus: false,
        refetchInterval: 5 * 60 * 10000,
    })
    if (world.data) {
        setTimeout(() => {
            rootStore.mapStore.setTileInfos(world.data.world)
            rootStore.mapStore.setTileInfosGenerated(world.data.generated)
        }, 1)
    }
    return world
}

export const useConnectedPlayers = () => {
    const gameId = useGameId()
    return useQuery({
        queryKey: ["connected"],
        queryFn: () => getConnectedPlayers(gameId),
        refetchOnWindowFocus: false,
    })
}

export const useProjects = () => {
    // get projects
    const gameId = useGameId()
    return useQuery({
        queryKey: ["projects"],
        queryFn: () => getProjects(gameId),
        staleTime: 60 * 1000,
    })
}
