import { Box, Icon, Link, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { RiShieldUserFill } from "react-icons/ri"
import { Link as ReachLink, useNavigate } from "react-router-dom"
import { debugAction } from "../../api/game"
import { useGameId, useHQInfo, usePlayer } from "../../hooks/query"
import { useIsDebug } from "../../hooks/utils"
import { useStore } from "../../store"
import { Factions } from "../../types"
// import { getTileInfo } from "../../api/world"

const ProfileButton: FunctionComponent = observer(() => {
    const { userStore, socketStore } = useStore()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const player = usePlayer()
    const showDebug = useIsDebug()
    const gameId = useGameId()

    const debug = useMutation({
        mutationFn: (params: any) => debugAction(gameId, params),
        onSuccess: () => queryClient.invalidateQueries(),
    })

    const changeUser = (faction: Factions) => {
        debug.mutate(
            { name: "change_user", faction },
            {
                onSuccess: (data) => {
                    userStore.setApiToken(data.token)
                    queryClient.invalidateQueries()
                },
            }
        )
    }

    const overflowServer = () => {
        for (let x = 0; x < 50; x++) {
            for (let y = 0; y < 50; y++) {
                // getTileInfo(x, y)
            }
        }
    }

    return (
        <>
            {!userStore.isAuthenticated && (
                <Link as={ReachLink} to="/login">
                    Login
                </Link>
            )}

            {userStore.isAuthenticated && player && (
                <Menu>
                    <MenuButton
                        bgColor="orange.500"
                        width="50px"
                        height="45px"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"sm"}
                        position="relative"
                    >
                        <Icon
                            as={RiShieldUserFill}
                            color={player?.faction}
                            width="100%"
                            height="100%"
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        />
                        {player?.hasPendingInvitation && (
                            <Box
                                display={"inline-block"}
                                borderRadius="50%"
                                width="8px"
                                height="8px"
                                bgColor="red.800"
                                position="absolute"
                                top="0px"
                                right="0"
                            ></Box>
                        )}
                    </MenuButton>
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                navigate("/games")
                            }}
                        >
                            Games
                        </MenuItem>
                        {/* <MenuItem
                            onClick={() => {
                                navigate("/group")
                            }}
                        >
                            Group
                            {player?.hasPendingInvitation && (
                                <Box
                                    display={"inline-block"}
                                    borderRadius="50%"
                                    width="8px"
                                    height="8px"
                                    bgColor="red.800"
                                    position="absolute"
                                    top="5px"
                                    right="0"
                                ></Box>
                            )}
                        </MenuItem> */}
                        <MenuItem
                            onClick={() => {
                                navigate("/settings")
                            }}
                        >
                            Settings
                        </MenuItem>
                        {(!userStore.player?.temporary || showDebug) && (
                            <MenuItem
                                onClick={() => {
                                    userStore.logout()
                                    navigate("/login")
                                }}
                            >
                                Logout
                            </MenuItem>
                        )}
                        {userStore.player?.temporary && (
                            <MenuItem
                                onClick={() => {
                                    navigate("/play/register")
                                }}
                            >
                                Register
                            </MenuItem>
                        )}

                        {showDebug && (
                            <>
                                <MenuDivider />
                                <MenuItem
                                    onClick={() => {
                                        debug.mutate({ name: "debug" })
                                    }}
                                >
                                    Debug (server call)
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        overflowServer()
                                    }}
                                >
                                    Debug (overflow)
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        socketStore.debug_createNotifications()
                                    }}
                                >
                                    Debug (full js)
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        debug.mutate({ name: "resources" })
                                    }}
                                >
                                    Give resources
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        changeUser(Factions.YELLOW)
                                    }}
                                >
                                    <Text color="yellow.600">Be yellow</Text>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        changeUser(Factions.GREEN)
                                    }}
                                >
                                    <Text color="green.600">Be green</Text>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        changeUser(Factions.BLUE)
                                    }}
                                >
                                    <Text color="blue.600">Be blue</Text>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        changeUser(Factions.RED)
                                    }}
                                >
                                    <Text color="red.600">Be red</Text>
                                </MenuItem>
                            </>
                        )}
                    </MenuList>
                </Menu>
            )}
        </>
    )
})

export default ProfileButton
