import { Box, Button, Checkbox, FormControl, FormHelperText, FormLabel, HStack, Heading, Input, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FormEvent, FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { updatePlayer } from "../api/player"
import { useStore } from "../store"
import Chat from "../components/chat/Chat"


const ChatPage: FunctionComponent = observer(() => {

    const queryClient = useQueryClient()
    const { chatStore } = useStore()


    return (
        <Box p="0">
           <Chat />
        </Box>
    )
})

export default ChatPage
