import { Box, Button, Card, CardBody, CardHeader, Center, Collapse, Flex, HStack, Heading, Text } from "@chakra-ui/react"
import { FunctionComponent, memo, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiDownArrow } from "react-icons/bi"
import { FinishedProject as FinishedProjectInterface } from "../../api/projects"
import Progress from "../../components/common/ProgressWithText"
import ModList from "../../components/resources/ModList"
import { FactionsWithoutNeutral } from "../../types"
import { AnimatedBox } from "../common/Motion"
import DateComponent from "../common/DateComponent"
import Resources from "../resources/Resources"

interface FinishedProjectProps extends FinishedProjectInterface {}

const FinishedProject: FunctionComponent<FinishedProjectProps> = memo(
    ({ id, name, first, second, third, baseEffects, gain, bonus, created, workers }) => {
        const { t } = useTranslation()

        const [isOpen, setIsOpen] = useState(false)

        // get current max value (winner) to compute progress percentage
        let maxValue = 100
        let factions = []
        const values = [workers.BLUE!, workers.GREEN!, workers.RED!, workers.YELLOW!]
        maxValue = Math.max(...values)
        for (let faction of Object.values(FactionsWithoutNeutral)) {
            const progress = workers[faction]!
            factions.push({
                faction: faction,
                progress: progress,
                color: faction,
            })
        }
        factions.sort((a, b) => (a.progress < b.progress ? 1 : b.progress < a.progress ? -1 : 0))

        return (
            <Card>
                <CardHeader bgColor={first}>
                    <HStack justifyContent={"space-between"} alignItems={"center"}>
                        <Heading ps="2" size="md" alignSelf={"center"}>
                            {t(`projects.${name}.title`)}
                        </Heading>
                        <Text pe="2" fontSize="xs">
                            Created on <DateComponent date={created} />
                        </Text>
                    </HStack>
                </CardHeader>

                <CardBody>
                    <Flex alignItems="center">
                        <Text>First : {first}</Text>
                        {!isOpen && (
                            <Button
                                ms="2"
                                variant="ghost"
                                onClick={() => setIsOpen(!isOpen)}
                                leftIcon={<BiDownArrow />}
                            >
                                Show details
                            </Button>
                        )}
                    </Flex>

                    <Collapse in={isOpen}>
                        <Box borderLeft="1px solid" borderColor="orange.600">
                            {factions.map((faction) => (
                                <AnimatedBox layout key={faction.faction}>
                                    <HStack gap={0}>
                                        <Progress
                                            value={faction.progress / maxValue}
                                            showText={false}
                                            total={1}
                                            progressColor={faction.color}
                                            height={"10px"}
                                            border="0"
                                        />
                                        <Box
                                            minW="80px"
                                            textAlign={"right"}
                                            whiteSpace={"nowrap"}
                                            borderLeft="1px solid"
                                            borderColor="orange.600"
                                        >
                                            <Text>{faction.progress}</Text>
                                        </Box>
                                    </HStack>
                                </AnimatedBox>
                            ))}
                        </Box>
                    </Collapse>

                    <ModList value={baseEffects} />
                    {Object.keys(gain).length > 0 && (
                        <Center fontSize="2xl">
                            <Resources {...gain} cost={false}></Resources>
                        </Center>
                    )}
                </CardBody>
            </Card>
        )
    },
    (prev, next) => prev.id === next.id
)

export default FinishedProject
