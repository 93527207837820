import { Progress, Text } from "@chakra-ui/react"
import { FunctionComponent, useEffect, useState, useRef } from "react"
import { useGatherResources } from "../hooks/query"
import CurrentResources from "./resources/CurrentResources"

let interval: number = 0
const PERIOD_DURATION = 20000

function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current!();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const GatherProgress: FunctionComponent = () => {
    const [progress, setProgress] = useState(0)
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [count, setCount] = useState(0)
    const { status, data, error, isFetching, refetch } = useGatherResources()

    // console.log(progress)

    // const progress = (currentDate - (startDate + count * PERIOD_DURATION)) / PERIOD_DURATION * 100
    // let progressInterval: number | null = null

    // const tick = () => {
    //     console.log("tick")
    //     interval = setTimeout(() => {
    //         setProgress(0)
    //         console.log("REFETCH")
    //         refetch().then(() => {
    //             setProgress(100)
    //         })
    //         tick()
    //     }, 20000)
    // }

    // const focus = () => {
    //     console.log("focus")
    //     clearTimeout(interval)
    //     setProgress(0)
    //     setTimeout(() => {
    //         tick()
    //         refetch()
    //         setProgress(100)
    //     }, 100)
    // }

    useInterval(() => {
        const currentDate = new Date().getTime()

        const progress = ((currentDate - (startDate + count * (PERIOD_DURATION))) / PERIOD_DURATION * 100)
        setProgress(progress)
        if (progress >= 105) {
            setCount(count => count + 1)
            refetch()
            setProgress(0)
        }
        if (progress >= 120) {
            // something bad happens, we're out of sync, restart
            setStartDate(new Date().getTime())
            setCount(0)
        }
    }, 500)

    useEffect(() => {
        // console.log("first refetch")
        refetch()
        // setProgress(100)
        // TODO : replace with settimeout (more reliable) and disable on focus

        // setInterval(() => {
        //     const currentDate = new Date().getTime()

        //     const progress = (currentDate - (startDate + count * (PERIOD_DURATION / 1000))) / PERIOD_DURATION * 100
        //     console.log("count", count)
        //     console.log(progress)
        //     setProgress(progress)
        //     if(progress >= 100){
        //         console.log("SET COUNT")
        //         setCount(count => count + 1)
        //     }
        // }, 500)

        // window.addEventListener("focus", focus)

        // progressInterval = setInterval(() => {
        //     console.log("progress")
        //     setProgress((progress) => progress + 5)
        // }, 1000)

        return () => {
            clearInterval(interval!)
            // window.removeEventListener("focus", focus)
            // clearInterval(progressInterval!)
        }
    }, [])

    // useEffect(() => {
    //     if(progress === 0){
    //         setTimeout(() => {
    //             console.log("set proghress to 100")
    //             setProgress(100)
    //         }, 100)
    //     }
    // }, [progress])

    return (
        <>
            <Progress
                value={progress}
                bgColor="orange.200"
                width={"full"}
                title="Gathering resources..."

                sx={{
                    "& > div:first-of-type": {
                        transitionProperty: "width",
                        transitionDuration: progress === 0 ? "0s" : "500ms",
                        transitionTimingFunction: "linear",
                        backgroundColor: "#10276b"
                    },
                }}
            ></Progress>
        </>
    )
}

export default GatherProgress
