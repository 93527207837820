import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Icon, Text, Tooltip } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'
import { useIsMobile } from "../../hooks/utils";


interface HelpTextPropsProps {
    children: React.ReactElement | string
    tooltip: React.ReactElement | string
    showHelpButton?: boolean
    popoverForMobile?: boolean /** render as a popover instead of tooltip */
}
const HelpText: FunctionComponent<HelpTextPropsProps> = ({
    children, tooltip, showHelpButton = true, popoverForMobile = true
}) => {
    const isMobile = useIsMobile()
    if (popoverForMobile && isMobile) {
        return (
            <Popover>
                <PopoverTrigger>
                    <Text as="span">{children} {showHelpButton && <Icon as={InfoOutlineIcon} top="-2px" position="relative" />}</Text>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>{tooltip}</PopoverBody>
                </PopoverContent>
            </Popover>
        )
    }
    return (
        <Tooltip label={tooltip}>
            <Text as="span">{children} {showHelpButton && <Icon as={InfoOutlineIcon} top="-2px" position="relative" />}</Text>
        </Tooltip>
    )
}

export default HelpText
