export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
export const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));
export const range = (x1: number, y1: number, x2: number, y2: number, a: number) => lerp(x2, y2, invlerp(x1, y1, a));

export const toShortNumber = (num: number): string => {
    if (num < 1000) return num.toString()
    if (num < 1000000) return (num / 1000).toFixed(1) + 'k'
    return (num / 1000000).toFixed(1) + 'm'
}

// @ts-ignore
Date.prototype.addHours = function (h: number) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
}

const memoize = (fn: Function) => {
    let cache: any = {};
    return (...args: any[]) => {
        let n = args[0];  // just taking one argument here
        if (n in cache) {
            console.log('Fetching from cache');
            return cache[n];
        }
        else {
            console.log('Calculating result');
            let result = fn(n);
            cache[n] = result;
            return result;
        }
    }
}