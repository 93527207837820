import { BuildingType, Factions, ResourceCost, ResourceType, SpecializationType, TerrainType, UnlockType, UserBuilding } from "../types";
import { authApi } from "./base";
import { queryClient } from "../query_client";
import { QuestType } from "../components/quests/config";
import { rootStore } from "../store";


/** 
* ping the server, to test the token, the server
*/
export async function ping() {
    return await authApi.get('game/ping')
}

/** a modificator (bonus or malus) */
export type Mod = {
    type: "production" | "storage" | "world" | "victoryPoints" | "talent"
    subtype: string
    bonus?: number
    base?: number
}
interface Building {
    name: BuildingType
    label: string
    cost: ResourceCost
    hq: number
    baseEffects: Mod[]
    requires: UnlockType
    unique?: boolean
}


type TerrainTypeBonus = {
    [key in TerrainType]: Mod[]
}
export interface Config {
    buildings: Building[]
    hqs: {
        [key in Factions]: { x: number, y: number }
    }
    world: TerrainType[][]
    terrainTypes: TerrainTypeBonus
    guardianBonus: number
    knightBonus: number
    misc: {
        powerCost: number
        tileImprovementBonus: number
        tileFortificationBonus: number
        fortificationTileLevels: [number, number, number, number]
        improvementTileLevels: [number, number, number, number]
        allowedFortification: TerrainType[]
        allowedImprovement: TerrainType[]
    }
}

export async function getConfig(gameId: number): Promise<Config> {
    const gameInfo = await authApi.get(`/game/${gameId}/hq/config`)
    return gameInfo.data
}


export type ResourceFrom = {
    /** explain from where this resource come from */
    from: string
    base?: number
    bonus?: number
}

export interface HQInfo {
    hq: {
        level: number
        maxNumberOfBuildings: number
        upgradeCost: ResourceCost
        faction: Factions
        specialization: SpecializationType
    }
    buildings: UserBuilding[]
    unlocks: UnlockType[]
    hasActiveQuest: boolean
}

export async function getHQInfo(gameId: number): Promise<HQInfo | undefined> {
    try {
        const gameInfo = await authApi.get(`/game/${gameId}/hq/info`)
        return gameInfo.data
    }
    catch (e: any) {
        console.log("catch")
        if (e.response.status === 401)
            rootStore.userStore.logout()
    }
}

export interface Effect {
    details: ResourceFrom[]
    total: number
}

export interface Effects {
    production: {
        [key in ResourceType]: Effect
    },
    storage: {
        [key in ResourceType]: Effect
    }
    talent: {
        talent: Effect
    }
    world: {
        worker: Effect
        attack: Effect
        defense: Effect
        globalAttack: Effect
        globalDefense: Effect
        knightPower: Effect
        guardianPower: Effect
        globalKnightPower: Effect
        globalGuardianPower: Effect
    }
}
export async function getEffects(gameId: number): Promise<Effects> {
    const gameInfo = await authApi.get(`/game/${gameId}/hq/effects`)
    return gameInfo.data
}

export async function upgradeHQ(gameId: number) {
    const response = await authApi.post(`/game/${gameId}/hq/upgrade_hq`)
    return response.data
}




export type Resources = {
    [key in ResourceType]?: number
}

export async function gatherResources(gameId: number): Promise<Resources> {
    const resources = await authApi.get(`/game/${gameId}/hq/gather_resources`)
    return resources.data
}

// export async function getResources(): Promise<Resources> {
//     const resources = await authApi.get('hq/get_resources')
//     return resources.data
// }

export async function build(gameId: number, name: BuildingType) {
    const response = await authApi.post(`/game/${gameId}/hq/build`, {}, { params: { name } })
    return response.data
}

export async function upgradeBuilding(gameId: number, id: number) {
    const response = await authApi.post(`/game/${gameId}/hq/upgrade_building`, {}, { params: { id } })
    return response.data
}

export async function destroyBuilding(gameId: number, id: number) {
    const response = await authApi.post(`/game/${gameId}/hq/destroy_building`, {}, { params: { id } })
    return response.data
}


// export async function getUnreadUnlocks():Promise<UnlockType[]> {
//     /** get unread unlocks */
//     const response = await authApi.get('hq/unread_unlocks')
//     return response.data
// }

// export async function markUnlockAsRead(name:UnlockType) {
//     /** get unread unlocks */
//     const response = await authApi.post('hq/read_unlock', {}, { params: { name } })
//     return response.data
// }

export interface ServerAchievement {
    name: string
    date: string | null
    progress: number | null
    goal: number | null
    earned: boolean
    visible: boolean
    unlock: UnlockType
}

export interface Achievements {
    achievements: ServerAchievement[]
    stats: {
        games: number
        wins: number
        bestPlayer: number
    }
}

export async function getAchievements(gameId: number): Promise<Achievements> {
    const response = await authApi.get(`/game/${gameId}/achievements/list`)
    return response.data
}

export interface Quest {
    name: QuestType
    completed: boolean
    reward: ResourceCost
}
export async function getQuest(gameId: number): Promise<Quest> {
    const response = await authApi.get(`/game/${gameId}/achievements/quest`)
    return response.data
}

export async function getReward(gameId: number) {
    const response = await authApi.post(`/game/${gameId}/achievements/reward`)
    return response.data
}

export async function debugAction(gameId: number, params: any) {
    const response = await authApi.post(`/game/${gameId}/hq/debug`, { ...params })
    return response.data
}


