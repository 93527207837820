import "./sentry"
import { Suspense } from "react"
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom"
import "./App.css"
import GameRoot from "./components/layout/GameRoot"
import Root from "./components/layout/Root"
import ErrorPage from "./pages/ErrorPage"
import Game from "./pages/Game"
import Login from "./pages/Login"
import { rootStore } from "./store"
import "./i18n"
import Settings from "./pages/Settings"
import Register from "./pages/Register"
import DebugPage from "./pages/DebugPage"
import GameOver from "./pages/GameOver"
import ChatPage from "./pages/ChatPage"
import ProfilePage from "./pages/ProfilePage"
import GamesPage from "./pages/GamesPage"
import GroupPage from "./pages/GroupPage"
import { Text } from "@chakra-ui/react"
import GameInfoPage from "./pages/GameInfoPage"

export const router = createBrowserRouter([
    {
        path: "/login",
        loader: async () => {
            if (rootStore.userStore.apiToken) {
                return redirect("/games")
            }
            return null
        },
        element: <Login />,
    },
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        // loader: async ({ params, request }) => {
        //     if (!rootStore.userStore.apiToken && !request.url.toLowerCase().endsWith("login")) {
        //         return redirect("/login")
        //     }
        //     return null
        // },
        children: [
            {
                path: "games",
                element: <GamesPage />,
            },
            {
                path: "games/:gameId",
                element: <GameInfoPage />,
                
            },
            {
                path: "group",
                element: <GroupPage />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
            {
                path: "gameover",
                element: <GameOver />,
            },
            {
                path: "register",
                element: <Register />,
            },
            {
                path: "player/:username",
                element: <ProfilePage />,
            },
            {
                id: "play",
                path: "play/:gameId",
                loader: async () => {
                    if (!rootStore.userStore.apiToken) {
                        return redirect("/login")
                    }
                    return null
                },
                element: <GameRoot />,
                children: [
                    {
                        path: "",
                        element: <Game />,
                    },
                    {
                        path: "hq",
                        element: <Game />,
                    },
                    {
                        path: "world",
                        element: <Game />,
                    },
                    {
                        path: "achievements",
                        element: <Game />,
                    },
                    {
                        path: "projects",
                        element: <Game />,
                    },
                    {
                        path: "stats",
                        element: <Game />,
                    },
                    {
                        path: "spec",
                        element: <Game />,
                    },
                    {
                        path: "chat",
                        element: <Game />,
                    },

                    {
                        path: "debug",
                        element: <DebugPage />,
                    },
                ],
            },
        ],
    },
])

function App() {
    return (
        <Suspense fallback="Loading...">
            <RouterProvider router={router} />
        </Suspense>
    )
}

export default App
