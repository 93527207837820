import { Box, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Loading from "../components/common/Loading"
import { useGame, useGameId, usePlayer } from "../hooks/query"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Button from "../components/common/Button"
import { useNavigate } from "react-router"
import { leaveLobby } from "../api/games"

const GameInfoPage: FunctionComponent = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const gameId = useGameId()
    const { data: game } = useGame()

    const leaveMutation = useMutation({
        mutationFn: () => leaveLobby(gameId),
        onSuccess: () => {
            navigate("/games")
            queryClient.invalidateQueries({ queryKey: ["games"] })
        },
    })

    if (!game) return <Loading />

    return (
        <Box p="4">
            <Heading as="h1">Game #{game.id}</Heading>
            <Text>
                Number of players : {game.numberOfPlayers} / {game.maxPlayers}
            </Text>
            <Text>Game status : {game.status}</Text>
            <Text>Game mode : {game.type}</Text>
            <Text>The game will starts as soon as there are enough players</Text>
            {game.participating && (
                <Button onClick={() => leaveMutation.mutate()} confirm colorScheme="red">
                    Leave lobby
                </Button>
            )}
        </Box>
    )
}

export default GameInfoPage
