import { Box, Flex, HStack, Heading, Link, useTheme } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { usePlayer } from "../../hooks/query"
import { useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import ProfileButton from "../header/ProfileButton"

import { Link as ReachLink } from "react-router-dom"

const SimpleHeader: FunctionComponent = observer(() => {
    const { userStore } = useStore()
    const isMobile = useIsMobile()
    const theme = useTheme()

    // mobile drawer
    const player = usePlayer()

    return (
        <Box
            id="navigation"
            as="nav"
            role="navigation"
            bg="orange.500"
            w="full"
            color="orange.100"
            display="flex"
            flexDir="column"
            justifyContent={"space-between"}
            position="sticky"
            top="0"
            p="2"
            zIndex="2"
        >
            <Flex justify="space-between">
                <>
                    <HStack gap="4" justifyContent={"space-between"} w="full">
                        <HStack gap="4">
                            <Heading>
                                <Link as={ReachLink} to="/games" variant="transparent">
                                    Factions
                                </Link>
                            </Heading>
                            <Link href="https://discord.gg/DsKSmetnqV" target="_blank" color="orange.100">
                                Join the discord !
                            </Link>
                        </HStack>
                        <Box>
                            <ProfileButton />
                        </Box>
                    </HStack>
                </>
            </Flex>
        </Box>
    )
})

export default SimpleHeader
