import { Box, HStack, Heading, Link, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Loading from "../components/common/Loading"
import { useGame, usePlayer } from "../hooks/query"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Button from "../components/common/Button"
import { useNavigate } from "react-router"
import { GameStatus, getGames, joinGame } from "../api/games"
import { getGroup } from "../api/player"

const GamesPage: FunctionComponent = () => {
    const queryClient = useQueryClient()

    const { data: games } = useQuery({
        queryKey: ["games"],
        queryFn: getGames,
        staleTime: 60 * 1000,
    })

    const joinMutation = useMutation({
        mutationFn: (gameId: number) => joinGame(gameId),
        onSuccess: (params, gameId) => {
            if (params.status == "error") {
                throw new Error("Can't join")
            }
            const game = games?.find((game) => game.id == gameId)
            queryClient.invalidateQueries({ queryKey: ["games"] })
            if (game!.status == GameStatus.PLAYING) navigate(`/play/${gameId}`)
            else navigate(`/games/${gameId}`)
        },
    })

    const { data: group, isPending } = useQuery({
        queryKey: ["group"],
        queryFn: getGroup,
        staleTime: 0,
    })
    const isLeader = group?.isLeader
    const inGroup = group?.id
    const groupSize = group?.members.length || 1

    const participatings = games?.filter((game) => game.participating) || []

    const navigate = useNavigate()

    console.log(games)
    if (!games) return <Loading />

    return (
        <Box p="4">
            <Heading as="h1">Games list</Heading>

            <Box layerStyle={"error"} m="2">
                <Text>
                    No games are currently opened or planned. Please{" "}
                    <Link href="https://discord.gg/DsKSmetnqV" target="_blank" color="orange.100">
                        join the Discord
                    </Link>{" "}
                    to be kept informed.
                </Text>
            </Box>

            {joinMutation.error && <Box layerStyle={"error"}>{joinMutation.error.message}</Box>}

            {participatings.length > 0 && (
                <Box>
                    <Text>You're already registered to {participatings.length} game(s) :</Text>

                    {participatings.map((game) => (
                        <HStack key={game.id}>
                            <Text key={game.id}>#{game.id}</Text>
                            {game.status == GameStatus.PLAYING && (
                                <Button onClick={() => navigate(`/play/${game.id}`)}>Join</Button>
                            )}
                            {game.status == GameStatus.LOBBY && (
                                <Button onClick={() => navigate(`/games/${game.id}`)}>Go to lobby</Button>
                            )}
                        </HStack>
                    ))}
                </Box>
            )}

            <Table>
                <Thead>
                    <Tr>
                        <Th>Game</Th>
                        <Th>Status</Th>
                        <Th>Type</Th>
                        <Th>Players</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {games.map((game) => {
                        const enoughPlace = game.maxPlayers - game.numberOfPlayers >= groupSize
                        return (
                            <Tr key={game.id}>
                                <Td>
                                    <Text>
                                        #{game.id} - {game.type}
                                    </Text>
                                </Td>
                                <Td>
                                    <Text>{game.status}</Text>
                                </Td>
                                <Td>
                                    <Text>Normal</Text>
                                </Td>
                                <Td>
                                    <Text>
                                        {game.numberOfPlayers}/{game.maxPlayers}
                                    </Text>
                                </Td>
                                <Td>
                                    {game.participating ? (
                                        <>
                                            <Text>Already in this game</Text>
                                            {game.status == GameStatus.PLAYING && (
                                                <Button colorScheme="blue" onClick={() => navigate(`/play/${game.id}`)}>
                                                    Play
                                                </Button>
                                            )}
                                            {game.status == GameStatus.LOBBY && (
                                                <Button
                                                    colorScheme="blue"
                                                    onClick={() => navigate(`/games/${game.id}`)}
                                                >
                                                    Go to lobby
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {game.isOpen && enoughPlace && (
                                                <Button
                                                    onClick={() => {
                                                        joinMutation.mutate(game.id)
                                                    }}
                                                    isDisabled={joinMutation.isPending}
                                                    isLoading={joinMutation.isPending}
                                                >
                                                    Join {isLeader ? "(with your group)" : "(solo)"}
                                                </Button>
                                            )}
                                            {game.isOpen && !enoughPlace && (
                                                <Text>Not enough place for your group</Text>
                                            )}
                                        </>
                                    )}
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </Box>
    )
}

export default GamesPage
