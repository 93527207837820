import { Box, Center, Container, Link, List, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"

import { NavLink as ReachLink } from "react-router-dom"
import { usePlayer } from "../../hooks/query"

interface MainMenuLinkProps {
    to: string
    children: string
}
const MainMenuLink = ({ to, children }: MainMenuLinkProps) => {
    return (
        <Link
            as={ReachLink}
            to={to}
            textDecoration={"none"}
            bgColor="orange.800"
            color="orange.100"
            _activeLink={{ bgColor: "orange.900", color: "orange.200" }}
        >
            <Box p="2" border="1px solid" borderColor="orange.900">
                {children}
            </Box>
        </Link>
    )
}

const MainMenu: FunctionComponent = () => {
    const player = usePlayer()

    return (
        <Box mt="4">
            <Stack p="2" gap="0">
                <MainMenuLink to="/games">Games</MainMenuLink>
                {/* <MainMenuLink to="/group">Group</MainMenuLink> */}
                <MainMenuLink to={`/player/${player?.username}`}>Profile</MainMenuLink>
                <MainMenuLink to="/settings">Settings</MainMenuLink>
            </Stack>
        </Box>
    )
}

export default MainMenu
