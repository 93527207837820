import { Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useTheme } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Message } from "../../store/ChatStore"
import { useNavigate } from "react-router-dom"
import { useStore } from "../../store"
import { WORLD_SIZE } from "../../config"
import { observer } from "mobx-react-lite"
import { Link as ReachLink } from "react-router-dom"
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react"
import DateComponent from "../common/DateComponent"
import { useIsUnlocked } from "../../hooks/query"
import { UnlockType } from "../../types"

interface MessageProps extends Message {
    channelId: string
    isLast: boolean
}
const MessageComponent: FunctionComponent<MessageProps> = observer(
    ({ id, content, player, created_at, channelId, isLast }) => {
        const theme = useTheme()
        const factionColor = theme.app.factions[player?.faction!] || "orange.300"
        const { chatStore } = useStore()
        const mapIsUnlocked = useIsUnlocked(UnlockType.UI_WORLD)

        // check content for links and wrap them in a tag
        const wrapLinks = (content: string) => {
            const regex = /(https?:\/\/[^\s]+)/g
            return content.replace(regex, (match) => `<a href="${match}" target="_blank">${match}</a>`)
        }

        // wrap xx:yy coordinates in a span
        const wrapCoordinates = (content: string) => {
            const regex = /(\d{1,2}:\d{1,2})/g

            return content.replace(regex, (match) => {
                const [x, y] = match.split(":")
                // check if x and y are int
                if (Number.isInteger(parseInt(x)) && Number.isInteger(parseInt(y))) {
                    let xn = parseInt(x)
                    let yn = parseInt(y)
                    if (xn >= WORLD_SIZE || yn >= WORLD_SIZE || xn < 0 || yn < 0) return match
                    return `<span class="coordinates" data-x="${x}" data-y="${y}">${match}</span>`
                } else {
                    return match
                }
            })
        }

        const wrappedContent = mapIsUnlocked ? wrapLinks(wrapCoordinates(content)) : content

        const channel = chatStore.getChannel(channelId)
        // check isLast because we don't show line for the last message
        const isLastRead = !isLast && channel?.lastReadMessage === id

        return (
            <Box>
                <ChakraLink as={ReachLink} to={`/play/player/${player.username}`} color={factionColor}>
                    {player.username}
                </ChakraLink>{" "}
                :{" "}
                <Text
                    className="message"
                    as="span"
                    fontSize="sm"
                    dangerouslySetInnerHTML={{ __html: wrappedContent }}
                ></Text>
                <Tooltip label={<DateComponent date={created_at * 1000} showTime showDate />}>
                    <Text fontSize="xs" as="span" pr="1" color="gray.300" display="inline-block" float="right">
                        <DateComponent date={created_at * 1000} showTime showDate={false} />
                    </Text>
                </Tooltip>
                {isLastRead && (
                    <HStack paddingX="2">
                        <Box width="100%" height="1px" bgColor="white"></Box>
                        <Text fontSize="xs">new</Text>
                        <Box width="100%" height="1px" bgColor="white"></Box>
                    </HStack>
                )}
            </Box>
        )
    }
)

export default MessageComponent
