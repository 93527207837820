import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    HStack,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    useTheme,
    Tooltip,
    Image,
    Fade,
    SlideFade,
    Center,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent, memo, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Project as ProjectInterface, work } from "../../api/projects"
import GameIcon from "../common/GameIcon"
import Progress from "../common/ProgressWithText"
import ModList from "../resources/ModList"
import { useConfig, useGameId, useProjects, useResources } from "../../hooks/query"
import { UISpendResources } from "../../hooks/utils"
import { useStore } from "../../store"
import { BuildingType, ProjectType, ResourceType } from "../../types"
import { observer } from "mobx-react-lite"
import Resources from "../resources/Resources"

interface ProjectProps {
    name: ProjectType
}

const Project: FunctionComponent<ProjectProps> = observer(({ name }) => {
    const { projectsStore } = useStore()
    const selected = projectsStore.selectedProject == name
    const gameId = useGameId()
    const { data: projects, isLoading, isError } = useProjects()
    const config = useConfig()
    const project = projects?.permanent.find((p: ProjectInterface) => p.name === name)

    const theme = useTheme()
    const { t } = useTranslation()

    const resources = useResources()
    const queryClient = useQueryClient()
    const { userStore } = useStore()

    // send workers
    const workMutation = useMutation({
        mutationFn: (workers: number) => work(gameId, name, workers),

        onMutate: (workers) => {
            // UISpendResources({ queryClient, resources: { workers: workers } })
        },
        onSuccess: (data) => {
            UISpendResources({ queryClient, resources: { workers: data.workersSent } })
            // handled by socket
            // queryClient.invalidateQueries(["projects"])
        },
    })

    if (!project) {
        return <Box>No project</Box>
    }

    const buildingInfo =
        project.type === "building"
            ? config?.buildings.find((building) => building.name == (name as unknown as BuildingType))
            : null

    return (
        <Card
            variant="elevated"
            opacity={project.unlocked ? 1 : 0.5}
            position="relative"
            onClick={() => projectsStore.setSelectedProject(name)}
            minWidth={"300px"}
            maxWidth={"300px"}
            cursor="pointer"
        >
            {/* <CardHeader bgColor={userStore.player?.faction}>
                <HStack justifyContent={"space-between"}>
                    <Heading ps="2" size="md">
                        {t(`projects.${name}.title`)}
                    </Heading>
                </HStack>
            </CardHeader> */}

            <CardBody bgColor={project.completed ? "teal.800" : "orange.500"}>
                {buildingInfo && (
                    <>
                        <Text>Unlock a new building</Text>
                        <HStack
                            alignItems={"start"}
                            border="1px solid"
                            borderColor="orange.600"
                            bgColor="teal.600"
                            color="orange.900"
                            boxShadow="md"
                        >
                            <Image src={`/images/buildings/${name.toLowerCase()}.png`} maxWidth={"60px"} />
                            <Stack gap="0">
                                <Text fontSize="md">{t(`buildings.${buildingInfo.name}.title`)}</Text>
                                <Box fontSize="0.7em">
                                    <ModList value={buildingInfo.baseEffects} />
                                </Box>
                            </Stack>
                        </HStack>
                    </>
                )}
                {project.type === "ui" && (
                    <Box bgColor="teal.600" color="orange.900" p="2">
                        <Tooltip label={t(`ui.${name}.description`)}>
                            <Text fontSize="md" as="span">
                                {t(`ui.${name}.title`)}
                            </Text>
                        </Tooltip>
                    </Box>
                )}
                {project.type === "gain" && (
                    <Center fontSize="2xl">
                        <Resources {...project.gain} cost={false}></Resources>
                    </Center>
                )}
                {project.baseEffects && <ModList value={project!.baseEffects} />}

                {!project.completed && (
                    <Progress value={project.workers} total={project.cost} height="40px" progressColor="teal.800" />
                )}
                {project.completed && (
                    <Text textAlign={"center"} fontSize="xl" mt="2">
                        Completed
                    </Text>
                )}

                <SlideFade in={project.unlocked && !project.completed && selected}>
                    <Stack
                        justifyContent={"center"}
                        position="absolute"
                        bgColor={userStore.player?.faction}
                        padding="2"
                        borderRadius={"sm"}
                        boxShadow={"md"}
                        minW={"400px"}
                    >
                        <Box>
                            <Text>
                                Send <GameIcon name={ResourceType.WORKER} /> workers{" "}
                            </Text>
                        </Box>
                        <SimpleGrid columns={[2, 2, 2, 4]} gap={1}>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(1)
                                }}
                                isDisabled={resources.workers < 1}
                            >
                                +1
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(10)
                                }}
                                isDisabled={resources.workers < 10}
                            >
                                +10
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(100)
                                }}
                                isDisabled={resources.workers < 100}
                            >
                                +100
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(Math.floor(resources.workers))
                                }}
                                isDisabled={resources.workers < 1}
                            >
                                +{Math.floor(resources.workers)}
                            </Button>
                        </SimpleGrid>
                    </Stack>
                </SlideFade>
            </CardBody>
        </Card>
    )
})

export default Project
